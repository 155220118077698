import { useState } from "react";
import Link from "next/link";
import { CircularProgress, Link as LinkMUI, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OpenInNew } from "@mui/icons-material";
import { downloadGenome } from "../api/hub";
import SignupModal from "../util/SignupModal";
import CustomDialog from "./CustomDialog";
import NewExperimentGenomeCard from "./new-experiment/NewExperimentGenomeCard";

const GenomeDownloadModal = ({
  modalOpen,
  setModalOpen,
  setSnackbar,
  genome,
  accessToken,
}) => {
  const theme = useTheme();
  const [signupModalOpen, setSignupModalOpen] = useState(false);

  const handleConfirmDownload = async () => {
    try {
      if (!accessToken) {
        setModalOpen(false);
        setSignupModalOpen(true);
        return;
      }

      if (!genome) return;

      await downloadGenome(genome.genome_id, genome.genome_title, accessToken);
      setModalOpen(false);
    } catch (err) {
      console.error(err);
      setSnackbar({
        message:
          "An error occurred downloading the genome. Please reload if issues persist.",
        severity: "error",
      });
    }
  };

  return (
    <>
      {modalOpen && (
        <CustomDialog
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          cancelAction={() => setModalOpen(false)}
          confirmAction={handleConfirmDownload}
          maxWidth="md"
          header="Download Genome"
          buttonText="Download"
          richText={
            <>
              <Typography sx={{ mb: 2 }}>
                Download <strong>{genome.genome_title}</strong> as a JSON that
                you can use with a local instance of{" "}
                <LinkMUI
                  href="https://github.com/feagi/feagi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FEAGI
                  <OpenInNew
                    fontSize="small"
                    sx={{ ml: "3px", fontSize: ".8rem" }}
                  />
                </LinkMUI>
                . Have fun exploring, or improve it and share it with the
                community!
              </Typography>

              {genome.genome_id ? (
                <>
                  <NewExperimentGenomeCard
                    accessToken={accessToken}
                    row={genome}
                    isHubModal={true}
                    setError={(message) =>
                      setSnackbar({
                        message: message,
                        severity: message ? "error" : "",
                      })
                    }
                  />
                  <Typography sx={{ mt: 2, fontSize: ".9rem" }}>
                    By downloading this genome, you agree to Neuraville&apos;s{" "}
                    <Link
                      href="/legal/terms-of-service"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Terms & Conditions
                    </Link>
                    .
                  </Typography>
                </>
              ) : (
                <CircularProgress />
              )}
            </>
          }
        />
      )}

      {signupModalOpen && (
        <SignupModal
          open={signupModalOpen}
          setOpen={setSignupModalOpen}
          authType="signup"
        />
      )}
    </>
  );
};

export default GenomeDownloadModal;
