import { MouseEvent, useEffect, useState } from "react";
import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
  Reddit as RedditIcon,
  Telegram as TelegramIcon,
  Share as ShareIcon,
  X as XIcon,
} from "@mui/icons-material";

const ShareWidget = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const description = "";

  useEffect(() => {
    setUrl(window?.location?.href);
    setTitle(document?.title);
  }, []);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "social-share-popover" : undefined;

  const openShareWindow = (shareUrl: string) => {
    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      shareUrl,
      "share-popup",
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
    handleClose();
  };

  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);

  // Ideal to include if they add sharing functionality: Discord, TikTok, IG
  const shareLinks = [
    {
      name: "Reddit",
      icon: RedditIcon,
      url: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
    },
    {
      name: "Facebook",
      icon: FacebookIcon,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${encodedTitle}&quote=${encodedDescription}`,
    },
    {
      name: "X",
      icon: XIcon,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
    },
    {
      name: "LinkedIn",
      icon: LinkedInIcon,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`,
    },
    {
      name: "Telegram",
      icon: TelegramIcon,
      url: `https://telegram.me/share/url?url=${encodedUrl}&text=${encodedDescription}`,
    },
    {
      name: "Email",
      icon: EmailIcon,
      url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A${encodedUrl}`,
    },
  ];

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        size="small"
        onClick={(event) => handleClick(event)}
        startIcon={<ShareIcon />}
        sx={{
          padding: "2px 6px",
          fontSize: "0.7rem",
          minWidth: "auto",
        }}
      >
        Share
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <Box
          sx={{
            width: "300px",
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            padding: 1,
          }}
        >
          {shareLinks.map((social) => {
            const Icon = social.icon;

            return (
              <Box
                key={social.name}
                sx={{
                  flexBasis: "calc(50% - 8px)", // Two items per row (50% width minus gap)
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  onClick={() => openShareWindow(social.url)}
                  startIcon={<Icon />}
                >
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{ minWidth: "70px", textAlign: "left" }}
                  >
                    {social.name}
                  </Typography>
                </Button>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default ShareWidget;
