import { useSearchParams } from "next/navigation";
import { forwardRef, useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import {
  augmentGenome,
  getGenomeFilters,
  toggleGenomeFavorite,
  toggleGenomePublic,
  updateGenome,
} from "../../api/genomeManagement";
import { deployGenome } from "../../api/feagiSessionManagement";
import { deleteGenome } from "../../api/genomeManagement";
import { useAuth } from "../../util/auth";
import { logger } from "../../util/logger";
import SignupModal from "../../util/SignupModal";
import CustomDialog from "../CustomDialog";
import Parentage from "../Parentage";
import ShareGenome from "../ShareGenome";
import TogglePublic from "../TogglePublic";
import GenomeDownloadModal from "../GenomeDownloadModal";
// import NewExperimentGenomeCardLarge from "./NewExperimentGenomeCardLarge";
import NewExperimentGenomeCardSmall from "./NewExperimentGenomeCardSmall";

const FeaturesDropdown = ({
  features,
  selectedFeatures,
  setSelectedFeatures,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFeatures(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ m: "8px 0", width: "100%" }}>
      <InputLabel id="features-label">Features</InputLabel>
      <Select
        labelId="features-label"
        id="features"
        multiple
        value={selectedFeatures}
        onChange={handleChange}
        input={<OutlinedInput label="Feature" />}
        MenuProps={MenuProps}
      >
        {features.map((feature) => (
          <MenuItem key={feature} value={feature}>
            {feature}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Example genome object
// {"base_genome_id": "2022-07-10_21:53:10_961677_111111_G", "genome_id": "g-evffncgfdj", "created_at": "2023-11-03T19:05:10.485000", "created_by": "Neuraville", "updated_at": "2023-11-03T19:05:10.485000", "updated_by": "NU-6862455679", "tags": [], "stars": 0, "public": true, "genome_title": "Base Genome", "species": "None", "performance_metrics": {}, "age_limit": 999999999, "state": "active", "version": "2.0", "seed_genome_id": "g-huuhhuxglp", "is_favorite": false, "owned": false, "creator_display_name": "70381"}

const NewExperimentGenomeCard = forwardRef(
  (
    {
      accessToken,
      row,
      // index,
      onComplete,
      collectedData,
      setCollectedData,
      setError,
      setSeedOpen,
      handleSeedChangeClose,
      // handleCurrentClose,
      handleCurrentAugmentClose,
      setAwaitAugment,
      setIsCalling,
      updateItem,
      setRefetchGenome,
      isHub,
      isHubModal,
    },
    ref
  ) => {
    const auth = useAuth();
    const roles = auth.user?.roles;
    // const theme = useTheme();
    // const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
    const [signupModalOpen, setSignupModalOpen] = useState(false);
    // Whether user has a high enough pricing tier to access this genome
    const [isPublic, setIsPublic] = useState(row.public);
    const [shareOpen, setShareOpen] = useState(false);
    const [isFave, setIsFave] = useState(row.is_favorite);
    const [faveCount, setFaveCount] = useState(row.stars);
    const [genealogyOpen, setGenealogyOpen] = useState(false);
    const [timeoutAugment, setTimeoutAugment] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);
    // Editing
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [features, setFeatures] = useState([]); // ALL features for selection dropdown
    const [selectedFeatures, setSelectedFeatures] = useState(row.tags || []);
    const [openDeleteCheck, setOpenDeleteCheck] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [confirmTogglePublicOpen, setConfirmTogglePublicOpen] =
      useState(false);
    const [confirmTogglePrivateOpen, setConfirmTogglePrivateOpen] =
      useState(false);
    // Stuff for brain-vis page
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const searchParams = useSearchParams();
    const sessionId = searchParams.get("id");
    const canChooseGenome =
      row?.genome_tier === "starter-user" ||
      (row?.genome_tier === "pro-user" &&
        (roles?.includes("pro-user") || roles?.includes("ultimate-user"))) ||
      (row?.genome_tier === "ultimate-user" &&
        roles?.includes("ultimate-user"));

    useEffect(() => {
      setRefetchGenome && setRefetchGenome((prev) => !prev);
    }, [isPublic, isFave, faveCount, setRefetchGenome]);

    // Handle user selection
    async function handleSelect(row) {
      if (onComplete) {
        try {
          setError("");
          setCollectedData({
            ...collectedData,
            genomeId: row.genome_id,
            genomeTitle: row.genome_title,
          });
          onComplete();
        } catch (err) {
          console.error(err);
          setError("Sorry, there was an error setting your genome selection.");
        }
      } else if (handleSeedChangeClose) {
        try {
          setError("");
          setConfirmationOpen(true);
        } catch (err) {
          console.error(err);
          setError("Sorry, there was an error confirming your change.");
        }
      } else if (handleCurrentAugmentClose) {
        handleCurrentAugment();
      }
    }

    // Turn off awaitAugment after 10 secs
    useEffect(() => {
      if (timeoutAugment) {
        setTimeout(() => {
          setAwaitAugment(false);
        }, 10000);
      }
    }, [setAwaitAugment, timeoutAugment]);

    // Augment current genome in BV
    async function handleCurrentAugment() {
      try {
        setError("");
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        if (!sessionId) throw new Error("Unable to retrieve session ID.");
        setIsCalling(true);
        await augmentGenome(accessToken, row.genome_id, sessionId);
        handleCurrentAugmentClose();
        // Set await augment & trigger its timeout
        setAwaitAugment(true);
        setTimeoutAugment(true);
      } catch (err) {
        console.error(err);
        if (err.status === 409) {
          setError(
            "An augmentation is already in progress. If you have completed augmentation steps in the brain visualizer, please wait a moment."
          );
        } else {
          setError(
            "There was an error adding the genome. Please reload and retry."
          );
        }
      } finally {
        setIsCalling(false);
      }
    }

    // Change seed genome in BV
    async function handleSeedChange() {
      try {
        setError("");
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        if (!sessionId) throw new Error("Unable to retrieve session ID.");
        await deployGenome(accessToken, sessionId, row.genome_id);
        handleConfirmationClose();
        handleSeedChangeClose();
      } catch (err) {
        console.error(err);
        handleConfirmationClose();
        setError(
          "There was an error changing your genome. Please reload and retry."
        );
      }
    }

    // Close seed genome change confirmation
    const handleConfirmationClose = () => {
      setConfirmationOpen(false);
    };

    // Genome to private handling
    const handleConfirmTogglePrivate = () => {
      setConfirmTogglePrivateOpen(false);
      togglePublic();
    };

    const handleConfirmTogglePrivateClose = () => {
      setConfirmTogglePrivateOpen(false);
    };

    // Toggle genome public/private
    async function togglePublic() {
      try {
        setError("");
        const genomeId = row.genome_id;
        if (!accessToken) throw new Error("Unable to get user data");
        if (!genomeId) throw new Error("Unable to get genome ID");
        await toggleGenomePublic(accessToken, genomeId);
        setIsPublic((prev) => !prev);
      } catch (error) {
        console.error(error);
        setSeedOpen && setSeedOpen(false);
        setError(
          "Sorry, something went wrong! Please try changing your experiment visibility again later."
        );
      }
    }

    // Toggle genome fave
    async function toggleFave() {
      try {
        setError("");
        const genomeId = row.genome_id;
        if (!accessToken) {
          setSignupModalOpen(true);
          return;
        }
        if (!genomeId) throw new Error("Unable to get genome ID");
        await toggleGenomeFavorite(accessToken, genomeId);
        isFave
          ? setFaveCount((prev) => prev - 1)
          : setFaveCount((prev) => prev + 1);
        setIsFave((prev) => !prev);
      } catch (error) {
        console.error(error);
        setSeedOpen && setSeedOpen(false);
        setError(
          "Sorry, something went wrong! Please try toggling your favorite again later."
        );
      }
    }

    // Get list of genome features for user to pick from in edit
    const fetchFeatures = async () => {
      try {
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        const response = await getGenomeFilters(accessToken);
        if (!response.data) {
          throw new Error("Received no data from the server.");
        }
        if (!response.data.genomes?.capabilities) {
          throw new Error("Filter list didn't return genome capabilities.");
        }
        setFeatures(response.data.genomes.capabilities.values);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    // Change to editing view
    const handleStartEdit = async () => {
      await fetchFeatures();
      setIsEditing(true);
    };

    // Submit title/description edits
    const handleEdit = async () => {
      logger("title:", title);
      logger("description:", description);
      logger("selected features:", selectedFeatures);
      try {
        // Update genome in backend
        await updateGenome({
          accessToken,
          genomeId: row.genome_id,
          title,
          description,
          features: selectedFeatures,
        });
        setRefetchGenome && setRefetchGenome((prev) => !prev);
        // Update this card in LazyLoader (if in all cards view)
        if (updateItem) {
          const updatedItem = {
            ...row,
            genome_title: title || row.genome_title,
            description: description || row.genome_description,
            tags: selectedFeatures,
          };
          updateItem(updatedItem);
        }
        // Reset states
        setIsEditing(false);
        setTitle(null);
        setDescription(null);
      } catch (err) {
        console.error(err);
        if (err.status && err.status === 418) {
          setError(
            "Your changes are detected to contain inappropriate language. Please edit and try again. (If you feel this is incorrect, please submit an issue report through the message icon in the navbar.)"
          );
        } else {
          setError(
            "Sorry, there was an error updating your genome. Please refresh and/or try again soon."
          );
        }
      }
    };

    // Cancel editing
    const handleCancelEdit = () => {
      // clearAlerts();
      // setTitle(experiment?.experiment_title);
      // setDescription(experiment?.experiment_description);
      setIsEditing(false);
    };

    // Delete genome
    const handleDelete = async () => {
      try {
        if (!accessToken || !row.genome_id) {
          throw new Error("Unable to get required data to delete genome.");
        }
        await deleteGenome(accessToken, row.genome_id);
        setIsDeleted(true);
      } catch (err) {
        console.error(err);
        setError(
          "An error occurred deleting your genome. Please reload to confirm and/or retry its deletion."
        );
      } finally {
        setOpenDeleteCheck(false);
      }
    };

    // Confirmation modal to change seed genome in BV
    const ConfirmBVSeedChange = () => {
      return (
        <CustomDialog
          header="Replace your current genome?"
          text="This will replace your experiment's genome with a genome that has
      the selected behavior."
          isOpen={confirmationOpen}
          handleClose={handleConfirmationClose}
          confirmAction={handleSeedChange}
          cancelAction={handleConfirmationClose}
        />
      );
    };

    // Confirmation modal to delete genome
    const ConfirmDeleteGenome = () => {
      const closeAction = () => setOpenDeleteCheck(false);
      return (
        <CustomDialog
          header={`Are you sure you want to delete ${row.genome_title}?`}
          isOpen={openDeleteCheck}
          handleClose={closeAction}
          confirmAction={handleDelete}
          cancelAction={closeAction}
        />
      );
    };

    // const handleChange = (event) => {
    //   const clickedFeature = event.target.value;
    //   if (selectedFeatures.includes(clickedFeature)) {
    //     const filtered = features.filter(
    //       (feature) => feature !== clickedFeature
    //     );
    //     setSelectedFeatures(filtered);
    //   } else {
    //     selectedFeatures.push(clickedFeature);
    //   }
    // };

    function getSharingTitle(row) {
      if (row.shared_with?.length) {
        const count = row.shared_with_count || row.shared_with.length;
        return `Shared with ${count} ${count > 1 ? "people" : "person"}`;
      } else {
        return "Share genome";
      }
    }

    const handleDownloadClick = () => {
      if (auth.user) {
        setDownloadOpen(true);
      } else {
        setSignupModalOpen(true);
      }
    };

    // Why this insane amount of props? Because only 2 components need these, and they need them all, and if I move them to each child I will have to remember to update 2 places in future
    const cardProps = {
      canChooseGenome,
      collectedData,
      description,
      faveCount,
      FeaturesDropdown,
      features,
      getSharingTitle,
      handleCancelEdit,
      handleCurrentAugmentClose,
      handleDownloadClick,
      handleEdit,
      handleSeedChangeClose,
      handleSelect,
      handleStartEdit,
      isEditing,
      isFave,
      isHub,
      isHubModal,
      isPublic,
      onComplete,
      ref,
      row,
      selectedFeatures,
      setDescription,
      setOpenDeleteCheck,
      setSelectedFeatures,
      setShareOpen,
      setTitle,
      title,
      toggleFave,
    };

    return isDeleted ? (
      <></>
    ) : (
      <>
        <NewExperimentGenomeCardSmall {...cardProps} />
        {/* {isBelowMd ? ( ) : (
          <NewExperimentGenomeCardLarge {...cardProps} />
        )} */}

        {/* Parentage modal */}
        {genealogyOpen && (
          <Parentage
            open={genealogyOpen}
            handleClose={() => setGenealogyOpen(false)}
            genome={row}
          />
        )}

        {/* Confirm seed change (in BV) modal */}
        <ConfirmBVSeedChange />

        {/* Confirm delete genome modal */}
        {onComplete && <ConfirmDeleteGenome />}

        {/* Confirm make genome public modal */}
        {confirmTogglePublicOpen && (
          <TogglePublic
            confirmTogglePublicOpen={confirmTogglePublicOpen}
            setConfirmTogglePublicOpen={setConfirmTogglePublicOpen}
            togglePublic={togglePublic}
          />
        )}

        {/* Confirm make genome private modal */}
        <CustomDialog
          header="Make your genome private?"
          text="This will hide your genome from other users. Any experiments/genomes already created by other users using this genome will remain."
          isOpen={confirmTogglePrivateOpen}
          handleClose={handleConfirmTogglePrivateClose}
          cancelAction={handleConfirmTogglePrivateClose}
          confirmAction={handleConfirmTogglePrivate}
        />

        {/* Share genome (with select users) modal */}
        {shareOpen && (
          <ShareGenome
            shareOpen={shareOpen}
            setShareOpen={setShareOpen}
            accessToken={accessToken}
            genome={row}
            updateGenome={updateItem}
            isPublic={isPublic}
            setConfirmTogglePrivateOpen={setConfirmTogglePrivateOpen}
            setConfirmTogglePublicOpen={setConfirmTogglePublicOpen}
          />
        )}

        {downloadOpen && (
          <GenomeDownloadModal
            modalOpen={downloadOpen}
            setModalOpen={setDownloadOpen}
            genome={row}
            accessToken={accessToken}
            setSnackbar={() =>
              setError(
                "An error occurred downloading the genome. Please reload and retry."
              )
            }
          />
        )}

        {/* Signup modal */}
        {signupModalOpen && (
          <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
        )}
      </>
    );
  }
);

NewExperimentGenomeCard.displayName = "NewExperimentGenomeCard";

export default NewExperimentGenomeCard;
