"use client";
import React from "react";
import { AuthProvider } from "../util/auth";
import { ThemeProvider } from "../styles/theme";
import { WebcamProvider } from "./contexts/WebcamContext";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider>
      <ThemeProvider>
        <WebcamProvider>{children}</WebcamProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}
