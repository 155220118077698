import { createContext, useContext, useState, ReactNode } from "react";

interface WebcamContextType {
  webcamOpen: boolean;
  setWebcamOpen: (_value: boolean) => void;
}

const WebcamContext = createContext<WebcamContextType | undefined>(undefined);

export function WebcamProvider({ children }: { children: ReactNode }) {
  const [webcamOpen, setWebcamOpen] = useState(false);

  return (
    <WebcamContext.Provider value={{ webcamOpen, setWebcamOpen }}>
      {children}
    </WebcamContext.Provider>
  );
}

export function useWebcam() {
  const context = useContext(WebcamContext);
  if (context === undefined) {
    throw new Error("useWebcam must be used within a WebcamProvider");
  }
  return context;
}
