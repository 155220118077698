"use client";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getPrivacyPolicy, getTermsOfUse } from "../../api/misc";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import LongContent from "../LongContent";
import PageLoader from "../PageLoader";
import Section from "../Section";

function LegalSectionSPA({
  startSection = "privacy-policy",
  snackbar,
  setSnackbar,
}) {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const [section, setSection] = useState(startSection);
  const [loading, setLoading] = useState(true);
  const [termsContent, setTermsContent] = useState("");
  const [privacyContent, setPrivacyContent] = useState("");

  useEffect(() => {
    async function getTerms() {
      try {
        const response = await getTermsOfUse();
        setTermsContent(response.data);
      } catch (error) {
        console.error("Error getting terms:", error);
        setSnackbar({ message: "", severity: "error" });
      }
    }

    async function getPrivacy() {
      try {
        const response = await getPrivacyPolicy();
        setPrivacyContent(response.data);
      } catch (error) {
        console.error("Error getting terms:", error);
        setSnackbar({ message: "", severity: "error" });
      }
    }

    setLoading(true);

    if (section === "privacy-policy" && !privacyContent) {
      getPrivacy();
    } else if (section === "terms-of-service" && !termsContent) {
      getTerms();
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  const handleTabChange = (event, newValue) => {
    setSection(newValue);
  };

  return (
    <Section
      size="auto"
      customStyle={{
        my: 1,
      }}
    >
      <>
        <Tabs
          value={section}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
          onChange={handleTabChange}
        >
          <Tab
            label={isBelowMd ? "Privacy" : "Privacy Policy"}
            value="privacy-policy"
          />
          <Tab
            label={isBelowMd ? "Terms" : "Terms of Service"}
            value="terms-of-service"
          />
        </Tabs>
        <Box>
          <LongContent
            customSx={{
              px: 2,
              maxHeight: "40vh",
              overflowY: "scroll",
              fontSize: ".75rem",
              border: "1px solid",
              borderColor: "accents.dark",
              borderRadius: "5px",
              backgroundColor: "#1d1d1d",
            }}
          >
            {loading ? (
              <PageLoader />
            ) : (
              <>
                {snackbar.severity === "error" ? (
                  <Alert severity="error">
                    <Typography>
                      Sorry, there was an error fetching the latest{" "}
                      {section === "privacy-policy"
                        ? "privacy policy"
                        : "Terms of Service"}
                      . It&apos;s likely that the server is down. Please retry
                      soon.
                    </Typography>
                  </Alert>
                ) : (
                  <DisplayMarkdown
                    markdownFromDb={
                      section === "privacy-policy"
                        ? privacyContent
                        : termsContent
                    }
                  />
                )}
              </>
            )}
          </LongContent>
        </Box>
      </>
    </Section>
  );
}

export default LegalSectionSPA;
