import { logger } from "./logger";

export default function isValidUrl(str) {
  if (!str || typeof str !== "string" || str.length === 0) {
    return false;
  }

  try {
    new URL(str);
    return true;
  } catch (err) {
    logger("Invalid URL:", str, err);
    return false;
  }
}
