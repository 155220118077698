import Tilt from "react-parallax-tilt";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
/* eslint-disable-next-line no-unused-vars */
import tiltStyles from "./tiltStyles.css";
import Image from "next/image";
import Link from "next/link";

const TiltCard = ({
  clickAction,
  link,
  image,
  text,
  pulse,
  isSmall,
  disabled = false,
  disabledText = "Stop any running experiment first",
}) => {
  const theme = useTheme();
  const isColumn = useMediaQuery(theme.breakpoints.down("md"));
  const isColumnNotSmall = isColumn && !isSmall;

  return (
    <Link
      href={link || "#"}
      onClick={(e) => {
        if (disabled || !link) {
          e.preventDefault();
          if (!disabled && clickAction) clickAction();
        }
      }}
      title={disabled ? disabledText : null}
      style={{
        flex: 1,
        width: "100%",
        maxWidth: isColumnNotSmall ? "500px" : isSmall ? "300px" : "100%",
        maxHeight: isSmall ? "200px" : "60vh",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
        color: "white",
        textDecoration: "none",
      }}
    >
      {/* Optional pulsing light around card on load */}
      <Box
        sx={{
          width: "100%",
          animation: pulse ? "pulse 2s ease-in-out 3" : null,
          "@keyframes pulse": pulse
            ? {
                "0%, 100%": {
                  boxShadow: `0 0 8px 0 ${theme.palette.primary.main}`,
                },
                "50%": {
                  boxShadow: "rgb(114 158 195 / 60%) 0px 0px 15px 5px",
                },
              }
            : null,
        }}
      >
        {/* Tilt effect */}
        <Tilt
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          className="tilt-card"
          style={{
            margin: isSmall ? "10px 0" : "5px 0",
            padding: isSmall ? "10px 10px 5px 10px" : "10px 20px 10px 20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "center",
            border: "1px solid",
            borderRadius: "5px",
            borderColor: "#24282d",
            transition: "background-color 0.3s ease",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            {text === "Play" ? (
              <Image
                src={image}
                alt="background"
                style={{
                  width: "100%",
                  height: isColumnNotSmall
                    ? "25vh"
                    : isSmall
                    ? "100px"
                    : "260px",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Image
                src={image}
                alt="background"
                width={0}
                height={0}
                sizes="100vh"
                style={{
                  width: "100%",
                  height: isColumnNotSmall
                    ? "25vh"
                    : isSmall
                    ? "100px"
                    : "260px",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
          <Typography
            sx={{
              width: "100%",
              py: isColumnNotSmall ? 2 : isSmall ? 1 : 3,
              textAlign: "center",
              fontSize: isColumnNotSmall
                ? "1.2rem"
                : isColumn
                ? "1rem"
                : "1.4rem",
              fontWeight: "600",
              // bgcolor: "#101214bd",
            }}
          >
            {text}
          </Typography>
        </Tilt>
      </Box>
    </Link>
  );
};

export default TiltCard;
