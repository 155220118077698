import config from "../config";

// Function to reset local storage data
export function resetFailureCount() {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem("apiFailureCount", "0");
    localStorage.setItem("lastApiCallTime", new Date().getTime().toString());
  }
}

// Function to count failures in a row & redirect if max reached
export function incrementFailureCount() {
  try {
    if (!window || !window.localStorage) {
      console.log("Window does not exist. Can't track API failures.");
      return;
    }
    // Get stored & current times
    const lastCallTime = localStorage.getItem("lastApiCallTime");
    const currentTime = new Date().getTime();

    // Reset if it's been a while since last call
    if (lastCallTime && currentTime - lastCallTime > config.resetTime) {
      resetFailureCount();
    }

    // Get stored failure count & increment
    let failureCount = parseInt(localStorage.getItem("apiFailureCount") || "0");
    failureCount++;

    // Set current count and time
    localStorage.setItem("apiFailureCount", failureCount.toString());
    localStorage.setItem("lastApiCallTime", currentTime.toString());

    // Redirect if max failures reached
    if (failureCount >= config.maxFailCount) {
      window.location.href = "/server-down";
    }
  } catch (err) {
    console.error("Error incrementing failure count:", err);
  }
}
