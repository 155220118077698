"use client";
import { useState, useRef, useEffect, useCallback } from "react";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import NavbarFeedbackSection from "./navbar/NavbarFeedbackSection";

const LazyLoader = ({
  fetchFunction,
  dependencies,
  limit,
  isEmbodiments,
  setError,
  loading,
  setLoading,
  children,
}) => {
  // const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(0);
  // const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [endOfTheLine, setEndOfTheLine] = useState(false);
  const observer = useRef();
  // Limit failed fetch retries
  const [fetchAttempts, setFetchAttempts] = useState(0);
  const maxAttempts = 10;
  // Show error messages
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  // Feedback modal for embodiments end
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const initialRender = useRef(true);
  const dependenciesInitialRender = useRef(true);

  // On page change, use History API to update URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageNum = parseInt(urlParams.get("page"), 10);

    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else if (pageNum >= currentPage) {
      return;
    }

    const url = new URL(window.location.href);
    url.searchParams.set("page", currentPage.toString());

    window.history.pushState(
      { page: currentPage },
      `Page ${currentPage}`,
      url.toString()
    );
  }, [currentPage]);

  // Use function from props to fetch items
  const fetchItems = async (pageString) => {
    let firstFetch = false;
    if (!items.length) firstFetch = true;

    // Stop loader from calling endlessly when error occurs
    if (fetchAttempts >= maxAttempts) {
      console.error(
        `Max failed fetch attempts (${maxAttempts}) reached. Stopping further attempts until reload.`
      );
      setError(
        "Continual errors fetching data. Please reload, or try again later."
      );
      return;
    }

    // Safeguard
    if (endOfTheLine) return;
    setLoading(true);

    // Fetch items
    try {
      let page = pageString || currentPage;

      if (!firstFetch) {
        page = pageString ? pageString + 1 : currentPage + 1;
        setCurrentPage(page);
      } else if (pageString) {
        setCurrentPage(pageString);
      }

      const data = await fetchFunction(page);

      if (!Array.isArray(data)) {
        throw new Error("Error fetching data. Please reload.");
      } else if (data.length === 0) {
        setEndOfTheLine(true);
      } else {
        setItems((prevItems) => [...prevItems, ...data]);
        if (data.length < limit) setEndOfTheLine(true);
      }

      setFetchAttempts(0);
      // Handle errors
    } catch (error) {
      console.error(error);
      setError(error.message || "Error fetching data. Please reload.");
      // setSnackbarOpen(true);
      setFetchAttempts((prev) => prev + 1);
    }
    setLoading(false);
  };

  // Fetch items on initial page load
  useEffect(() => {
    const callFetch = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const pageParam = urlParams.get("page");
      let pageString = null;

      if (pageParam) {
        pageString = parseInt(pageParam, 10);
      }

      !loading && fetchItems(pageString);
    };

    callFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refetch items after resets complete
  useEffect(() => {
    const callFetch = async () => {
      if (!loading && shouldRefetch > 0) {
        fetchItems();
      }
    };

    callFetch();

    // Clean up observer
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  // Reset when dependencies change
  useEffect(() => {
    if (dependenciesInitialRender.current) {
      dependenciesInitialRender.current = false;
      return;
    }
    setItems([]);
    setEndOfTheLine(false);
    setCurrentPage(1);
    const url = new URL(window.location.href);
    url.searchParams.delete("page");
    window.history.replaceState(null, "", url.toString());
    // setSkip(0);
    setShouldRefetch((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  // Use observer to track last item in current results, & load more items when scrolled to last one
  const lastItemRef = useCallback(
    (node) => {
      if (loading || endOfTheLine) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !endOfTheLine) {
          fetchItems();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, endOfTheLine]
  );

  // Handle user edits
  const updateItem = (updatedItem) => {
    setItems((prevItems) => {
      return prevItems.map((item) =>
        item.genome_id === updatedItem.genome_id ? updatedItem : item
      );
    });
  };

  return (
    <>
      {/* Items */}
      {items && children({ items, lastItemRef, updateItem })}

      {/* Loading spinner */}
      {loading && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* "End of results" */}
      {endOfTheLine && (
        <Box
          sx={{
            width: "100%",
            my: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography sx={{ fontStyle: "italic", color: "accents.main" }}>
            End of results
          </Typography>

          {/* Offer feedback modal if on embodiments page */}
          {isEmbodiments && (
            <Typography>
              Didn&apos;t find the robot you were looking for?{" "}
              <Typography
                variant="span"
                sx={{
                  fontWeight: "500",
                  color: "primary.main",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setFeedbackOpen(true)}
              >
                Let us know!
              </Typography>
              <Dialog
                open={feedbackOpen}
                onClose={() => {
                  setFeedbackOpen(false);
                }}
              >
                <NavbarFeedbackSection setFeedbackOpen={setFeedbackOpen} />
              </Dialog>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default LazyLoader;
