// import aauoxsin from "../../img/em-aauoxsin.png"; // robot arm
// import kxrhxvtv from "../../img/em-bsfuiref.png"; // cutebot microbit
// import kbxamajf from "../../img/em-kbxamajf.png"; // freenove
// import nbnhzdqc from "../../img/em-nbnhzdqc.png"; // drone
// import dgocpqka from "../../img/em-zkmemyyd.png"; // pong

// const imageMap = {
//   aauoxsin: aauoxsin,
//   kxrhxvtv: kxrhxvtv,
//   kbxamajf: kbxamajf,
//   nbnhzdqc: nbnhzdqc,
//   dgocpqka: dgocpqka,
// };

"use client";
import { forwardRef, useRef, useState } from "react";
import Link from "next/link";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Build,
  OpenInNew,
  PersonAdd,
  ReportProblemOutlined,
  // TouchApp,
  UnfoldLess,
  UnfoldMore,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import formatForParams from "../../util/formatForParams";
import { handleNewlines } from "../../util/handleNewlines";
import isValidUrl from "../../util/isValidUrl";
import GetOne from "../GetOne";
import Image from "next/image";

const NewExperimentEmbodimentCardSmall = forwardRef(
  ({ row, onComplete, collectedData, setCollectedData, browserInfo }, ref) => {
    const auth = useAuth();
    const theme = useTheme();
    const [showMore, setShowMore] = useState(false);
    const embodimentPath = `/embodiment/${formatForParams(
      `${row.embodiment_title} by ${row.manufacturer}`
    )}/${row.embodiment_id}`;
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef(null);

    const handleCopy = () => {
      navigator.clipboard
        .writeText(`${window.location.origin}${embodimentPath}`)
        .then(() => {
          setAnchorEl(buttonRef.current);
          setTimeout(() => setAnchorEl(null), 1000); // Close after x ms
        });
    };

    function handleSelect(row) {
      if (setCollectedData) {
        setCollectedData({
          ...collectedData,
          embodimentId: row.embodiment_id,
          embodimentTitle: row.embodiment_title,
          embodimentUrl: row.image_url,
          embodimentGenomeId: row.barebones_genome_id,
          embodimentProposed:
            !row.experiment_integration_status ||
            row.experiment_integration_status === "proposed",
        });
      }
      onComplete(row.embodiment_id, row.embodiment_title);
    }

    return (
      <Card
        ref={ref ? ref : undefined}
        sx={{
          position: "relative",
          // overflow: "visible",
          width: "100%",
          "&:hover .hoverButton": {
            opacity: 1,
          },
        }}
      >
        {/* Proposed icon */}
        {row.experiment_integration_status === "proposed" && (
          <>
            <Tooltip title="Proposed for future integration">
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "35px 35px 0 0",
                  transform: "rotate(180deg)",
                  borderColor: (theme) =>
                    `transparent ${theme.palette.tertiary.main} transparent transparent`,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 3,
                  left: 3,
                  zIndex: 1,
                }}
              >
                <Build
                  sx={{
                    fontSize: "1rem",
                    color: "white",
                    transform: "rotate(90deg)",
                  }}
                />
              </Box>
            </Tooltip>
          </>
        )}
        <CardContent
          sx={{
            width: "100%",
            pt: "16px",
            pb: "16px !important",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* Left Column */}
          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box
                sx={{
                  width: "100%",
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                {/* Title */}
                <Box
                  sx={{
                    marginBottom: "10px",
                    ...(!showMore
                      ? {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }
                      : {}),
                  }}
                >
                  <Typography
                    sx={{
                      overflowWrap: "break-word",
                      fontSize: { xs: "1rem", md: "1.2rem" },
                      fontWeight: 600,
                      whiteSpace: "normal",
                    }}
                  >
                    {row.embodiment_title}
                  </Typography>
                  {row.manufacturer && (
                    <Typography
                      sx={{
                        color: "accents.light",
                        fontSize: { xs: ".85rem", md: ".99rem" },
                      }}
                    >
                      by {row.manufacturer}
                    </Typography>
                  )}
                  {/* External product button */}
                  {isValidUrl(row.product_url) && (
                    <GetOne
                      accessToken={auth.user?.accessToken}
                      embodiment={row}
                      sx={{ mt: 2, mb: 1 }}
                      size="small"
                    />
                  )}
                </Box>
                {/* Embodiment image */}
                <Box
                  sx={{
                    position: "relative",
                    alignSelf: "flex-start",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "110px",
                    height: "80px",
                  }}
                >
                  {row.image_url && (
                    <Image
                      src={row.image_url}
                      alt={row.embodiment_title}
                      crossOrigin="anonymous"
                      width={0}
                      height={0}
                      sizes="100vh"
                      style={{
                        paddingBottom: "8px",
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* Feature tags */}
              {row.tags?.length > 0 &&
                row.tags.some((tag) => tag.trim().length) && (
                  <Box sx={{ width: "100%", mb: 1, display: "flex", gap: 0.5 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        width: "100%",
                        flexWrap: "wrap",
                        maxHeight: !showMore ? "35px" : "none",
                        overflow: !showMore ? "hidden" : "visible",
                      }}
                    >
                      {row.tags.map((tag, index) => {
                        return (
                          <Chip
                            key={index}
                            label={tag}
                            sx={{
                              "&.MuiChip-root": {
                                maxWidth: "100%",
                                borderRadius: "5px",
                                backgroundColor: "accents.dark",
                                "& .MuiChip-label": {
                                  color: "white",
                                  fontSize: {
                                    xs: "0.7rem",
                                    md: "0.8125rem",
                                  },
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                },
                              },
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                )}
              {/* Environment */}
              {row.environment_type && showMore && (
                <Typography
                  sx={{
                    marginBottom: "10px",
                    fontSize: { xs: ".9rem", md: ".99rem" },
                  }}
                >
                  <span
                    style={{
                      ...(!showMore
                        ? {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }
                        : {}),
                    }}
                  >
                    <strong>Environment:</strong>
                  </span>{" "}
                  {row.environment_type}
                </Typography>
              )}
              {/* Species */}
              {row.species && showMore && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  gap="50px"
                >
                  <Typography
                    sx={{
                      marginBottom: "10px",
                      fontSize: { xs: ".9rem", md: ".99rem" },
                      width: "100%",
                      ...(!showMore
                        ? {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }
                        : {}),
                    }}
                  >
                    <strong>Species:</strong> {row.species}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Bluetooth unsupported warning */}
            {row.agent_type === "nrs-bt-device" &&
              !browserInfo?.isMobile &&
              (browserInfo?.browser === "Safari" ||
                browserInfo?.browser === "Firefox") && (
                <Box
                  sx={{
                    mb: 1.5,
                    mt: 0.5,
                    display: "flex",
                    alignItems: "flex-end",
                    gap: 1,
                  }}
                >
                  <ReportProblemOutlined
                    color="warning"
                    sx={{
                      opacity: "0.4",
                      fontSize: "1.5rem",
                    }}
                  />
                  <Typography
                    color="warning"
                    sx={{ fontSize: ".9rem", opacity: ".8" }}
                  >
                    Browser{" "}
                    <Link
                      href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Bluetooth_API#browser_compatibility"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: theme.palette.warning.main }}
                    >
                      may not support
                    </Link>{" "}
                    Bluetooth! Try Chrome.
                  </Typography>
                </Box>
              )}

            {/* Description */}
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  ...{
                    fontSize: { xs: "0.9rem", md: ".99rem" },
                    fontStyle: row?.description ? "normal" : "italic",
                    color: row?.description ? "white" : "accents.light",
                  },
                  ...(showMore
                    ? {}
                    : {
                        wordBreak: "break-word",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }),
                }}
              >
                {row?.description
                  ? handleNewlines(row.description)
                  : "No description"}
                {showMore && (
                  <Tooltip title="More details">
                    <Link
                      href={embodimentPath}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                    >
                      <IconButton
                        size="small"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      >
                        <Typography
                          color="primary"
                          variant="span"
                          sx={{ fontSize: ".9rem" }}
                        >
                          More Details
                        </Typography>

                        <OpenInNew
                          color="primary"
                          sx={{ ml: 0.5, fontSize: "1rem" }}
                        />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </Typography>
            </Box>
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "30%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: { xs: "flex-end", md: "center" },
              }}
            >
              <Box
                className="hoverButton"
                sx={{
                  opacity: { xs: 1, sm: 0 },
                  transition: "opacity 0.1s ease",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "10px",
                }}
              ></Box>
            </Box>
            {/* Show more button */}
            {/* {!showMore ? (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Show more embodiment details">
                  <Button
                    variant="text"
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "accents.main",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandMoreIcon />
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              // Show less button
              <Box display="flex" justifyContent="end">
                <Tooltip title="See less embodiment details">
                  <Button
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "accents.main",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandLessIcon />
                  </Button>
                </Tooltip>
              </Box>
            )} */}
          </Box>

          <Box
            sx={{
              alignSelf: "flex-end",
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, md: 3 },
            }}
          >
            {/* Share */}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{ pointerEvents: "none" }}
            >
              <Typography sx={{ p: 2 }}> Copied link!</Typography>
            </Popover>
            <Tooltip title="Get link to share">
              <IconButton
                color="primary"
                size="small"
                ref={buttonRef}
                onClick={handleCopy}
              >
                <PersonAdd />
              </IconButton>
            </Tooltip>
            {/* Show more icon */}
            <Tooltip title={`Show ${showMore ? "less" : "more"}`}>
              <IconButton
                color="primary"
                onClick={() => setShowMore((prev) => !prev)}
              >
                {showMore ? <UnfoldLess /> : <UnfoldMore />}
              </IconButton>
            </Tooltip>
            {/* Choose button */}
            {onComplete && (
              <Tooltip title="Choose">
                <span>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{
                      ml: 0.5,
                      fontWeight: "600",
                      // width: "30px",
                      // minWidth: 0,
                      // py: 0.3,
                    }}
                    onClick={() => handleSelect(row)}
                  >
                    {/* <TouchApp /> */}
                    Choose
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }
);

NewExperimentEmbodimentCardSmall.displayName =
  "NewExperimentEmbodimentCardSmall";

export default NewExperimentEmbodimentCardSmall;
