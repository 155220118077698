import { forwardRef, useEffect, useState } from "react";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import {
  AppBar,
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Tooltip,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CenterFocusStrong as CenterFocusStrongIcon,
  PrecisionManufacturing,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import InactivityTimer from "../../util/InactivityTimer";
import getBrowserInfo from "../../util/getBrowserInfo";
import CustomLink from "../../util/CustomLink";
import CustomSnackbar from "../../util/CustomSnackbar";
import FullscreenLoader from "../../util/FullscreenLoader";
import handleLeavePlay from "../../util/handleLeavePlay";
import { getDropdownInfo } from "../../api/misc";
import CustomDialog from "../CustomDialog";
import ExperimentCardMemory from "../lab-experiments/ExperimentCardMemory";
import NavbarEmbodiment from "./NavbarEmbodiment";
import NavbarExperiment from "./NavbarExperiment";
import NavbarFitness from "./NavbarFitness";
import NavbarFreezeBrain from "./NavbarFreezeBrain";
import NavbarGenome from "./NavbarGenome";
import NavbarMainNav from "./NavbarMainNav";
import Section from "../Section";
import ScreenCapture from "../embodiments-code/ScreenCapture";
import SignupModal from "../../util/SignupModal";
import Image from "next/image";

// color, webcamOpen, setWebcamOpen,
const Navbar = forwardRef((props, ref) => {
  const auth = useAuth();
  const accessToken = auth.user?.accessToken;
  const [fullscreenLoader, setFullscreenLoader] = useState(false);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  // Path & params
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const sessionId = searchParams.get("id");
  const clusterId = searchParams.get("cluster");
  // Access screen size anywhere
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [navActive, setNavActive] = useState(false);
  // const darkMode = useDarkMode();
  // const logoColor = darkMode.value ? "white" : "black";
  const logoColor = "white";
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // brain visualizer page
  const [isMobile, setIsMobile] = useState(false);
  const [experiment, setExperiment] = useState(null);
  const [isEmbodimentConnected, setIsEmbodimentConnected] = useState(false);
  const [isMicrophoneChecked, setIsMicrophoneChecked] = useState(false);
  const [godotEmbodimentOpen, setGodotEmbodimentOpen] = useState(false);
  const [screenCaptureOpen, setScreenCaptureOpen] = useState(false);
  const [embodimentFitness, setEmbodimentFitness] = useState(null);
  const [fitnessOpen, setFitnessOpen] = useState(false);
  const [awaitAugment, setAwaitAugment] = useState(false);
  const [refetchExperiment, setRefetchExperiment] = useState(false);

  const isAuthPage = pathname.startsWith("/auth");
  const shouldOnlyShowIfActive = isSmOrSmaller && isAuthPage;

  // Colors to indicate environment
  const backgroundColor =
    process.env.NEXT_PUBLIC_NODE_ENV === "staging"
      ? "rgb(18 55 46)"
      : "transparent";

  // Show navbar depending on scroll from top
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }
    };

    isSmOrSmaller &&
      isAuthPage &&
      window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAuthPage, isSmOrSmaller]);

  // const customSetError = (obj) => {
  //   setError(obj.message);
  // };

  useEffect(() => {
    // Remove scrollbars and bound draggables only on brain-vis page
    document.body.style.overflow = "";
    // Get browser info
    const callGetBrowserInfo = async () => {
      const info = await getBrowserInfo();
      setIsMobile(info?.isMobile);
    };
    if (pathname.startsWith("/brain-visualizer")) {
      document.body.style.overflow = "hidden";
      callGetBrowserInfo();
    }
  }, [pathname]);

  // Fetch experiment dropdown info
  useEffect(() => {
    const getLatestExperimentInfo = async () => {
      try {
        const accessToken = auth?.user?.accessToken;
        const res = await getDropdownInfo(accessToken, sessionId);
        setExperiment(res.data);
      } catch (error) {
        console.error(error);
        setError(
          error.message ||
            "There was an error fetching your experiments. Please reload."
        );
      }
    };

    if (
      pathname.startsWith("/brain-visualizer") &&
      auth?.user?.accessToken &&
      sessionId
    ) {
      getLatestExperimentInfo();
    }
  }, [pathname, sessionId, refetchExperiment, auth?.user?.accessToken]);

  useEffect(() => {
    if (!pathname.startsWith("/brain-visualizer")) {
      setGodotEmbodimentOpen(false);
      props.setWebcamOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleSignOut = async () => {
    try {
      if (!auth) {
        throw new Error("Auth object not found:", auth);
      }
      setFullscreenLoader(true);
      await auth.signout();
      // Only change route if the sign out process has completed successfully
      // history.push("/auth/signin");
      // history.push(`${process.env.NEXT_PUBLIC_COMPOSER_URL}/auth/signin`);
      window.location.href = `/`;
    } catch (error) {
      console.error("Signout failed: ", error);
    } finally {
      setFullscreenLoader(false);
    }
  };

  const linkClick = (targetPath) => {
    const clusterParam =
      searchParams.get("cluster") || searchParams.get("cluster_id");
    if (pathname.startsWith("/play") && clusterParam) {
      setLeavePlayPath(targetPath);
    } else {
      window.location.href = targetPath;
    }
  };

  // Use inverted logo if specified and we are in dark mode
  // const logo = props.logo;
  // props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  // useEffect(() => {
  //   try {
  //     auth && auth.user && auth.refreshRoles();
  //   } catch (err) {
  //     setError(
  //       "There was an error retrieving your account information. This may impact your experience. Please refresh or contact us if you continue to experience issues."
  //     );
  //   }
  //   // Ignore dependency array demands -- auth.js handles role setting after auth changes and putting auth here causes circular dependency
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {!pathname?.startsWith("/embodiment") && auth?.user && (
        <InactivityTimer />
      )}
      <Section
        bgcolor={shouldOnlyShowIfActive && navActive ? "black" : props.color}
        size="auto"
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          ref={ref}
          sx={{
            display: shouldOnlyShowIfActive && !navActive ? "none" : "flex",
            position:
              shouldOnlyShowIfActive && navActive ? "fixed" : "relative",
            zIndex: 100,
            backgroundColor:
              shouldOnlyShowIfActive && navActive
                ? "background.default"
                : props.color,
          }}
        >
          <Container
            disableGutters={true}
            maxWidth="100vw" // this is a set Container prop, don't move it to sx
            sx={{
              px: { xs: "10px", md: "20px" },
              backgroundColor: backgroundColor,
            }}
          >
            <Toolbar
              disableGutters={true}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {(pathname === "/" || pathname === "/lab") && isSmOrSmaller ? (
                <></>
              ) : (
                <Tooltip title="Home">
                  <span>
                    {/* This button passes on params if no user (signup analytics), 
                  else checks path and either shows confirmation modal or redirects */}
                    <Button
                      component={!auth.user ? CustomLink : "button"}
                      href={auth.user ? null : "/"}
                      type="mui"
                      onClick={auth.user ? () => linkClick("/") : undefined}
                      sx={{
                        py: 0.3,
                        minWidth: "64px",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "flex-start",
                        gap: { xs: 0, sm: 0.5 },
                        fontSize: { xs: ".7rem", sm: ".87rem" },
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: logoColor,
                      }}
                    >
                      {isSmOrSmaller &&
                      pathname.startsWith("/brain-visualizer") ? (
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            maxWidth: "30px",
                          }}
                        >
                          <Image
                            src="/img/logo-800-gray.png"
                            alt="Neuraville logo"
                            width={0}
                            height={0}
                            sizes="100vh"
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      ) : (
                        <>
                          Neurorobotics
                          <Badge
                            badgeContent="BETA"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              ".MuiBadge-badge": {
                                top: "30%",
                                right: "-27px",
                                transform: "translateY(-50%)", // Centers vertically
                                fontSize: "0.42rem",
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                color: "primary.main",
                              },
                            }}
                          >
                            <span style={{ fontWeight: "400" }}>Studio</span>
                          </Badge>
                        </>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )}

              {pathname.startsWith("/brain-visualizer") && !isMobile && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: "5px", md: "20px" },
                      borderLeft: "1px solid white",
                      marginLeft: { xs: "10px", md: "20px" },
                      marginRight: { xs: "10px", md: 0 },
                      paddingLeft: { xs: "10px", md: "20px" },
                    }}
                  >
                    <NavbarExperiment
                      accessToken={accessToken}
                      experimentId={experiment?.experiment_id}
                      experimentTitle={experiment?.experiment_title}
                      clusterId={clusterId}
                      sessionId={sessionId}
                      setError={setError}
                      setMessage={setMessage}
                      awaitAugment={awaitAugment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    <NavbarEmbodiment
                      experiment={experiment}
                      accessToken={accessToken}
                      setError={setError}
                      setMessage={setMessage}
                      sessionId={sessionId}
                      clusterId={clusterId}
                      isEmbodimentConnected={isEmbodimentConnected}
                      setIsEmbodimentConnected={setIsEmbodimentConnected}
                      webcamOpen={props.webcamOpen}
                      setWebcamOpen={props.setWebcamOpen}
                      godotEmbodimentOpen={godotEmbodimentOpen}
                      setGodotEmbodimentOpen={setGodotEmbodimentOpen}
                      isMicrophoneChecked={isMicrophoneChecked}
                      setIsMicrophoneChecked={setIsMicrophoneChecked}
                      screenCaptureOpen={screenCaptureOpen}
                      setScreenCaptureOpen={setScreenCaptureOpen}
                      setEmbodimentFitness={setEmbodimentFitness}
                      awaitAugment={awaitAugment}
                      setRefetchExperiment={setRefetchExperiment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    <NavbarGenome
                      experiment={experiment}
                      accessToken={accessToken}
                      error={error}
                      setError={setError}
                      setMessage={setMessage}
                      setRefetchExperiment={setRefetchExperiment}
                      awaitAugment={awaitAugment}
                      setAwaitAugment={setAwaitAugment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    {/* {experiment?.has_personalities && (
                      <NavbarBehaviors
                        embodimentId={experiment?.embodiment_id}
                        accessToken={accessToken}
                        setSnackbar={customSetError}
                        awaitAugment={awaitAugment}
                        isSessionValid={true}
                      />
                    )} */}
                  </Box>
                  <ScreenCapture
                    screenCaptureOpen={screenCaptureOpen}
                    setScreenCaptureOpen={setScreenCaptureOpen}
                    sessionId={sessionId}
                    clusterId={clusterId}
                    setError={setError}
                  />
                  {/* Augment await message */}
                  <Snackbar
                    open={awaitAugment}
                    onClose={() => setAwaitAugment(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "&.MuiSnackbar-root": { top: "7px" },
                    }}
                  >
                    <Alert severity="info">
                      Augmentation in progress. Please follow the instructions
                      in the brain visualizer.
                    </Alert>
                  </Snackbar>
                </>
              )}

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />

              {pathname.startsWith("/brain-visualizer") && !isMobile && (
                <>
                  {embodimentFitness && (
                    <Tooltip title="Brain fitness. Click for more details">
                      <span>
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={embodimentFitness === null}
                          onClick={() => setFitnessOpen(true)}
                          sx={{
                            marginRight: { xs: "5px", lg: "40px" },
                            minWidth: { xs: "auto", md: "64px" },
                            fontWeight: "600",
                            borderColor: "#fff",
                            color: "#fff",
                            "&:hover": {
                              borderColor: "#ffffff8f",
                            },
                          }}
                        >
                          {!isSmOrSmaller && "Fitness "}
                          {embodimentFitness}%
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Box
                    sx={{
                      height: "30.75px", // match Button height
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      border: "1px solid rgb(87, 87, 87)",
                      borderRadius: "5px",
                      px: { xs: "5px", md: "10px" },
                      py: "5px",
                      marginRight: { xs: "5px", lg: "40px" },
                    }}
                  >
                    <Tooltip
                      title={
                        isEmbodimentConnected || godotEmbodimentOpen
                          ? "Embodiment Status: Connected"
                          : "Embodiment Status: Not Connected"
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "1.5rem",
                          color:
                            isEmbodimentConnected || godotEmbodimentOpen
                              ? "success.main"
                              : "accents.main",
                        }}
                      >
                        <PrecisionManufacturing />
                      </Box>
                    </Tooltip>
                    {/* <Tooltip
                    title={
                      isMicrophoneChecked
                        ? "Mic Status: Connected"
                        : "Mic Status: Not Connected"
                    }
                  >
                    <MicIcon
                      style={{
                        color: isMicrophoneChecked
                          ? "success"
                          : "rgb(87, 87, 87)",
                      }}
                    />
                  </Tooltip> */}
                    <Tooltip
                      title={
                        props.webcamOpen
                          ? "Webcam: Connected"
                          : screenCaptureOpen
                          ? "Screen Capture: Connected"
                          : "Webcam & Screen Capture: Not Connected"
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "1.5rem",
                          color:
                            props.webcamOpen || screenCaptureOpen
                              ? "success.main"
                              : "accents.main",
                        }}
                      >
                        <CenterFocusStrongIcon />
                      </Box>
                    </Tooltip>
                  </Box>

                  {/* Grafana */}
                  {/* <Grafana awaitAugment={awaitAugment} /> // this is same thing as below, don't double
                  <Tooltip title="Monitor brain activity [PRO]">
                    <Link
                      href={`https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/docs`}
                    >
                      <IconButton
                        style={{ borderRadius: "5px", padding: "5px" }}
                        disabled={awaitAugment}
                        // disabled={true} // change to role !== pro etc.
                      >
                        <Insights
                          title="Monitor brain activity [PRO]"
                          sx={{
                            margin: "0 5px",
                          }}
                        />
                      </IconButton>
                    </Link>
                  </Tooltip> */}

                  {/* Freeze brain */}
                  {(auth.user?.roles?.includes("pro-user") ||
                    auth.user?.roles?.includes("ultimate-user")) && (
                    <>
                      <NavbarFreezeBrain
                        accessToken={accessToken}
                        experiment={experiment}
                        sessionId={sessionId}
                        setError={setError}
                        setMessage={setMessage}
                        awaitAugment={awaitAugment}
                      />
                      {/* Change memory tier */}
                      <ExperimentCardMemory />
                    </>
                  )}

                  <NavbarFitness
                    fitnessOpen={fitnessOpen}
                    setFitnessOpen={setFitnessOpen}
                    sessionId={sessionId}
                    clusterId={clusterId}
                  />
                </>
              )}

              <NavbarMainNav
                auth={auth}
                linkClick={linkClick}
                handleSignOut={handleSignOut}
                path={pathname}
                setError={setError}
              />
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <List sx={{ width: 250 }} onClick={() => setDrawerOpen(false)}>
            {!auth.user && (
              <ListItemButton
                variant="outlined"
                onClick={() => setSignupModalOpen(true)}
                sx={{ color: "white" }}
              >
                <ListItemText>Sign In</ListItemText>
              </ListItemButton>
              // <ListItemButton component={CustomLink} href="/">
              //   <ListItemText>Sign in</ListItemText>
              // </ListItemButton>
            )}

            {auth.user && (
              <>
                <ListItemButton component={Link} to="/">
                  <ListItemText>Home</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/settings/account">
                  <ListItemText>Settings</ListItemText>
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={handleSignOut}>
                  <ListItemText>Sign out</ListItemText>
                </ListItemButton>
              </>
            )}

            {/* <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem> */}
          </List>
        </Drawer>
      </Section>

      {leavePlayPath && (
        <CustomDialog
          header="Leave this page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(accessToken, leavePlayPath, setLeavePlayPath)
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}

      {/* Error message */}
      {error && (
        <CustomSnackbar
          message={error}
          setMessage={setError}
          severity="error"
        />
      )}

      {/* Info message */}
      {message && (
        <CustomSnackbar
          message={message}
          setMessage={setMessage}
          autoHide={12000}
          severity="info"
        />
      )}

      {/* Fullscreen loading spinner */}
      {fullscreenLoader && <FullscreenLoader message="Logging out..." />}

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
      )}
    </>
  );
});

Navbar.displayName = "Navbar";

export default Navbar;
