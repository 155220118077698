"use client";
import { useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import formatDate from "../util/formatDate";
import Image from "next/image";

const UserBadge = ({ badge }) => {
  const [isFlipped, setIsFlipped] = useState(null);

  const handleFlip = () => {
    setIsFlipped((prev) => !prev);
  };

  if (!badge) return null;

  return (
    <Card
      key={badge.id}
      onMouseEnter={() => handleFlip(badge.id)}
      onMouseLeave={() => handleFlip(badge.id)}
      onClick={() => handleFlip(badge.id)}
      sx={{
        position: "relative",
        width: { xs: "100px", md: "125px" },
        height: { xs: "140px", md: "165px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        border: 1,
        borderRadius: "5px",
        borderColor: "background.light",
        backgroundColor: "background.default",
        perspective: "1000px",
        transformStyle: "preserve-3d",
        transition: "transform 0.6s",
        transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          p: 1,
          width: "100%",
          height: "100%",
          backfaceVisibility: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Image
            src={badge.img_url}
            alt={badge.img_alt_text}
            width={0}
            height={0}
            sizes="100vh"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography
          sx={{
            mt: "5px",
            fontSize: { xs: ".5rem", md: ".65rem" },
            fontWeight: "600",
            textTransform: "uppercase",
            color: "white",
          }}
        >
          {badge.name}
        </Typography>
        {badge.earned_date && (
          <Typography
            sx={{
              fontSize: { xs: ".45rem", md: ".6rem" },
              color: "accents.light",
            }}
          >
            {formatDate(badge.earned_date)}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          p: 1,
          backfaceVisibility: "hidden",
          transform: "rotateY(180deg)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.dark",
        }}
      >
        <Typography
          sx={{
            mt: "5px",
            fontSize: { xs: ".5rem", md: ".65rem" },
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Earned For
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: ".45rem", md: ".6rem" },
            color: "accents.light",
            textAlign: "center",
          }}
        >
          {badge.description}
        </Typography>
      </Box>
    </Card>
  );
};

export default UserBadge;
