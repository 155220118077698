import { useEffect, useRef } from "react";
import { logger } from "../../util/logger";
import getStatusCodeString from "../../util/websocketStatusCodes";

function Bluetooth({
  isMicrobitOpen,
  setIsMicrobitOpen,
  isMicrobitConnected,
  setIsMicrobitConnected,
  embodiment,
  sessionAndCluster,
  setSnackbar,
}) {
  // Session & cluster IDs
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const sessionId = params.get("id") || params.get("session_id");
  // const clusterId = params.get("cluster") || params.get("cluster_id");
  // Embodiment
  const embodimentBluetoothProperties = embodiment?.bluetooth_properties;
  const embodimentId = embodiment?.embodiment_id;
  const embodimentCapabilities = embodiment?.capabilities;
  // WebSocket
  const wsRef = useRef(null);
  const wsUrl = `wss://${sessionAndCluster?.sessionId}-feagi.${sessionAndCluster?.clusterId}.neurorobotics.studio/p9052?device=${embodimentId}`;
  // Bluetooth
  const bluetoothUARTRef = useRef(null);
  const initialRenderRef = useRef(true);
  const wsIntervalRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (
        sessionAndCluster?.sessionId &&
        sessionAndCluster?.clusterId &&
        !wsRef.current
      ) {
        console.log("Retrying websocket");
        connectWebSocket();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionAndCluster]);

  // Format data for WS with controller
  const formatData = (data) => {
    if (!data) {
      console.error("Data from embodiment is null. Not sending.");
      return null;
    } else {
      return JSON.stringify({
        [embodimentId]: { data, timestamp: Date.now() },
      });
    }
  };

  // Connect/disconnect on switch click
  useEffect(() => {
    if (isMicrobitOpen && !isMicrobitConnected) {
      connectMicrobit();
    } else if (!isMicrobitOpen) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitOpen]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }
    if (!isMicrobitConnected) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitConnected]);

  // Connect WebSocket to controller
  const connectWebSocket = () => {
    if (!sessionAndCluster?.sessionId || !sessionAndCluster?.clusterId) {
      console.error("Session or cluster ID is missing. Can't connect WS.");
      return;
    }

    if (wsRef.current) {
      console.error("WebSocket already open. Not opening another.");
      return;
    }

    wsRef.current = new WebSocket(wsUrl);

    wsRef.current.onopen = () => {
      logger("WebSocket opened");

      if (embodimentCapabilities) {
        wsRef.current?.send(JSON.stringify(embodimentCapabilities));
        console.log("Sent embodiment capabilities to controller.");
        // const formattedData = formatData(embodimentCapabilities);
        // wsRef.current?.send(formattedData);
      } else {
        console.error(
          "Bluetooth embodiment capabilities undefined/null. Cannot send."
        );
      }

      wsIntervalRef.current = setInterval(() => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
          wsRef.current?.send(JSON.stringify({ type: "ping" }));
          console.log("Sent ping to controller.");
        } else {
          console.error("WebSocket not open. Cannot send ping.");
        }
      }, 5000);

      logger("Setting new send interval:", wsIntervalRef.current);
    };

    wsRef.current.onmessage = (event) => {
      logger("Received message from controller:", event.data);

      if (!bluetoothUARTRef.current) {
        console.error("Bluetooth UART not initialized. Ignoring message.");
      } else if (!event.data) {
        console.error("Data from controller is null. Ignoring message.");
      } else {
        bluetoothUARTRef.current?.send(event.data);
      }
    };

    wsRef.current.onerror = (error) => console.error("WebSocket error:", error);

    wsRef.current.onclose = (event) => {
      logger(
        `WebSocket connection closed: Code ${event.code} ${getStatusCodeString(
          event.code
        )}.`
      );

      if (wsIntervalRef.current) {
        console.log("WebSocket closed: clearing send interval");
        clearInterval(wsIntervalRef.current);
        wsIntervalRef.current = null;
      }
    };
  };

  // Bluetooth UART class
  class BluetoothUART {
    constructor(rxCharacteristic, txCharacteristic) {
      this.rxCharacteristic = rxCharacteristic;
      this.txCharacteristic = txCharacteristic;
      this.decoder = new TextDecoder();
    }

    async initialize() {
      try {
        const characteristic = await this.txCharacteristic.startNotifications();
        characteristic.addEventListener(
          "characteristicvaluechanged",
          this.onCharacteristicValueChanged.bind(this)
        );
      } catch (error) {
        throw new Error(error);
      }
    }

    onCharacteristicValueChanged(event) {
      let valueAsString = this.decoder.decode(event.target.value);
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        // logger("got data from bt:", valueAsString);
        const formattedData = formatData(valueAsString);
        formattedData && wsRef.current.send(formattedData);
      } else {
        console.error("WebSocket not open. Cannot send data.");
      }
    }

    async send(data) {
      const encoder = new TextEncoder();
      const encoded = encoder.encode(data);
      await this.rxCharacteristic.writeValue(encoded);
      logger("Sent to Bluetooth device:", data);
    }
  }

  // Connect to BT device
  const connectMicrobit = async () => {
    try {
      if (
        !embodimentBluetoothProperties ||
        !embodimentBluetoothProperties.length
      ) {
        throw new Error("Missing Bluetooth properties from database.");
      }

      const bluetoothSearchOptions = {
        acceptAllDevices: true,
        optionalServices: embodimentBluetoothProperties.map(
          (prop) => prop.service
        ),
      };

      const device = await navigator?.bluetooth?.requestDevice(
        bluetoothSearchOptions
      );

      if (!device) {
        throw new Error(
          "Unable to get device info. This browser may not support Bluetooth. Please try a different browser."
        );
      }

      device.ongattserverdisconnected = () => {
        console.log("Bluetooth device disconnected");
        disconnectMicrobit();
      };

      const server = await device.gatt.connect();

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(5000);

      let uart = null;

      // Try connection with all UUID sets from DB before showing error
      for (let i = 0; i < embodimentBluetoothProperties.length; i++) {
        try {
          const properties = embodimentBluetoothProperties[i];

          const service = await server.getPrimaryService(properties.service);
          const rxCharacteristic = await service.getCharacteristic(
            properties.rx
          );
          const txCharacteristic = await service.getCharacteristic(
            properties.tx
          );

          const potentialUart = new BluetoothUART(
            rxCharacteristic,
            txCharacteristic
          );
          await potentialUart.initialize();

          uart = potentialUart;
          break;
        } catch (error) {
          if (i === embodimentBluetoothProperties.length - 1) {
            throw new Error(error);
          } else {
            console.warn(
              `Error starting notifications for this UUID set. ${error} Trying another...`
            );
            continue;
          }
        }
      }

      if (!uart) {
        throw new Error("Unable to create Bluetooth UART.");
      }

      bluetoothUARTRef.current = uart;

      setIsMicrobitConnected(true);

      console.log("Bluetooth device connected");

      // Setup WebSocket connection after Bluetooth device is connected
      connectWebSocket();
    } catch (error) {
      const message = `Connection error: ${error}`;

      console.error(message);

      if (!message?.includes("User cancelled")) {
        if (
          error.name === "NotSupportedError" ||
          error.message?.includes("NotSupportedError")
        ) {
          setSnackbar({
            message:
              "Incompatible device selected. If your model matches the one shown, please submit a bug report.",
            severity: "error",
          });
        } else if (error.message?.includes("Unsupported device")) {
          setSnackbar({
            message:
              "Unsupported device error. If you're on a Mac, you may need to visit Settings -> Bluetooth and forget the device to reset permissions.",
            severity: "error",
          });
        } else {
          setSnackbar({
            message: `Error establishing communication with Bluetooth device: ${error}`,
            severity: "error",
          });
        }
      }

      disconnectMicrobit();
    }
  };

  // Disconnect from BT device
  const disconnectMicrobit = () => {
    console.log("Disconnection initiated");

    if (wsRef.current) {
      console.log("Disconnect: closing websocket");
      wsRef.current.close();
      wsRef.current = null;
    }

    if (navigator?.bluetooth?.getDevices) {
      navigator.bluetooth.getDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.gatt.connected) {
            console.log("Disconnect: Disconnecting Bluetooth device");
            device.gatt.disconnect();
          }
        });
      });
    }

    if (bluetoothUARTRef.current) {
      console.log("Disconnect: Setting UART ref to null");
      bluetoothUARTRef.current = null;
    }

    console.log("Disconnect: Setting microbit/embodiment to closed");
    setIsMicrobitConnected(false);
    setIsMicrobitOpen(false);
  };

  // const toggleOpen = () => {
  //   if (isMicrobitConnected) {
  //     disconnectMicrobit();
  //   } else {
  //     connectMicrobit();
  //   }
  // };

  return (
    <>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography>Cutebot</Typography> */}
      {/* <IconButton
            sx={{ fontSize: "1rem", padding: 0 }}
            onClick={handleEmbodimentOpen}
          >
            <InfoIcon style={{ fontSize: "1.5rem", padding: "4px" }} />
          </IconButton> */}
      {/* </Box>
        <Switch
          onClick={
            browserInfo?.browser === "Safari"
              ? () => setUnsupportedAlertOpen(true)
              : toggleOpen
          }
          checked={!!isMicrobitConnected}
        />
      </Box>
      <Snackbar
        open={unsupportedAlertOpen}
        autoHideDuration={6000}
        onClose={() => setUnsupportedAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setUnsupportedAlertOpen(false)}
          severity="warning"
        >
          Safari does not currently support Bluetooth connectivity. Please
          switch to Chrome.
        </Alert>
      </Snackbar> */}
    </>
  );
}

export default Bluetooth;

// const helloClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("f60#");
//   // };

//   // const backwardClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("b60#");
//   // };

//   // const leftClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("l60#");
//   // };

//   // const rightClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("r60#");
//   // };

//   // const sayHelloBack = (message) => {
//   //   ourMicrobitUART && ourMicrobitUART.send("hello", "response");
//   // };

//   // const printMessage = () => {
//   //   setMessage("Hello, world!");
//   // };

/* <Box display="flex" justifyContent={"center"}>
        <button type="button" onClick={connectClicked}>
          Connect!
        </button>
        <button type="button" onClick={helloClicked}>
          Go forward
        </button>
        <button type="button" onClick={backwardClicked}>
          Go backward
        </button>
        <button type="button" onClick={leftClicked}>
          Go left
        </button>
        <button type="button" onClick={rightClicked}>
          Go right
        </button>
        <button onClick={printMessage}>Print Message</button>
        <p>{message}</p>
      </Box>  */

/* <div>
        {log.map((logEntry, index) => (
          <div key={index}>{logEntry}</div>
        ))}
      </div> */
