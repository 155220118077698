import { Button } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { externalProductClick } from "../api/embodimentManagement";

const GetOne = ({
  accessToken,
  embodiment,
  size = "medium",
  color = "secondary",
  variant = "contained",
  sx,
}) => {
  if (!embodiment) return null;

  async function handleGetOneClick() {
    try {
      window.open(embodiment.product_url, "_blank");
      await externalProductClick(accessToken, embodiment.embodiment_id);
    } catch (err) {
      console.error("Error informing server of product click:", err);
    }
  }

  return (
    <Button
      variant={variant}
      color={color}
      onClick={handleGetOneClick}
      sx={{
        minWidth: "83px",
        padding:
          size === "small"
            ? "4px 8px"
            : size === "medium"
            ? { xs: "4px 8px", md: "6px 16px" }
            : null,
        fontSize:
          size === "small"
            ? ".75rem"
            : size === "medium"
            ? { xs: ".65rem", md: ".875rem" }
            : null,
        fontWeight: "600",
        ...sx,
      }}
    >
      Get One!{" "}
      <OpenInNew
        sx={{
          ml: "3px",
          opacity: ".5",
          fontSize: { xs: ".75rem", md: "1rem" },
        }}
      />
    </Button>
  );
};

export default GetOne;
