import React, { useEffect, useState } from "react";
import CustomDialog from "../CustomDialog";
import LegalSectionSPA from "../legal/LegalSectionSPA";
import { Alert, Box, Checkbox, FormControlLabel } from "@mui/material";
import { givePrivacyConsent } from "../../api/userManagement";
import { useAuth } from "../../util/auth";

const PrivacyModal = ({
  isOpen,
  setIsOpen,
  completeAction,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  completeAction: () => void;
}) => {
  const auth = useAuth() as { user: any };
  const [checked, setChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [boxesUnchecked, setBoxesUnchecked] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", severity: "" });

  const handleAcceptPolicies = async () => {
    try {
      if (!checked || !emailChecked) {
        setBoxesUnchecked(true);
        return;
      }
      await givePrivacyConsent(auth.user?.accessToken);
      await completeAction();
      setIsOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (checked && emailChecked) setBoxesUnchecked(false);
  }, [checked, emailChecked]);

  return (
    <CustomDialog
      isOpen={isOpen}
      handleClose={() => {}}
      cancelAction={null}
      confirmAction={handleAcceptPolicies}
      isDisabled={snackbar.severity === "error"}
      actionsChildren={null}
      closeButton={false}
      maxWidth="md"
      header="We value your privacy"
      text="To continue, please confirm the below items."
      buttonText="Confirm"
      richText={
        <>
          <Box
            sx={{
              mb: 2,
              mt: 1,
            }}
          >
            <LegalSectionSPA
              startSection="privacy-policy"
              snackbar={snackbar}
              setSnackbar={setSnackbar}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  size="small"
                  disabled={snackbar.severity === "error"}
                  sx={{
                    mr: 0.5,
                    color: "primary.main",
                  }}
                />
              }
              label="I agree to the Privacy Policy and Terms of Service."
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: ".85rem", md: ".95rem" },
                  color: "accents.light",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailChecked}
                  onChange={(event) => setEmailChecked(event.target.checked)}
                  size="small"
                  disabled={snackbar.severity === "error"}
                  sx={{
                    mr: 0.5,
                    color: "primary.main",
                  }}
                />
              }
              label="I accept that my email will only be used for authentication & Neuraville communications."
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: ".85rem", md: ".95rem" },
                  color: "accents.light",
                },
              }}
            />
          </Box>
          {boxesUnchecked && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Please check both boxes to confirm your agreement.
            </Alert>
          )}
        </>
      }
    />
  );
};

export default PrivacyModal;
