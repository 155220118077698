import Image from "next/image";
import { Children, ReactNode, isValidElement, JSX } from "react";
import { Box } from "@mui/material";
import { TipsAndUpdates } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import DOMPurify from "isomorphic-dompurify";
import CustomLink from "./CustomLink";

const Heading = (Tag: keyof JSX.IntrinsicElements) => {
  const Component = ({ children }: { children: ReactNode }) => {
    const headingLevel = parseInt(Tag.replace("h", "")); // Extract number
    const fontLarge = `calc(2.3rem - ${headingLevel - 1} * 0.25rem)`;
    const fontSmall = `calc(1.2rem - ${headingLevel - 1} * 0.25rem)`;

    return (
      <Tag
        style={{
          marginBottom: "12px",
          fontWeight: "500",
          fontSize: `clamp(${fontSmall}, 4vw, ${fontLarge})`,
        }}
      >
        {children}
      </Tag>
    );
  };
  Component.displayName = `Heading${Tag}`;
  return Component;
};

const DisplayMarkdown = ({ markdownFromDb }: { markdownFromDb: string }) => {
  const sanitizedMarkdown = DOMPurify.sanitize(markdownFromDb);

  const renderers = {
    img: ({
      src,
      alt,
      title,
      className,
    }: {
      src: string;
      alt: string;
      title?: string;
      className?: string;
    }) => {
      // Video
      if (className === "video-embed" || alt?.includes("video")) {
        const loop = alt?.includes("loop");
        return (
          <Box
            title={title}
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <video
              muted
              autoPlay={loop}
              controls={!loop}
              playsInline
              loop={loop}
              style={{
                maxHeight: "500px",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            >
              <source src={src} type="video/mp4" />
            </video>
          </Box>
        );
        // Embedded iframe video
      } else if (className === "iframe-embed" || alt?.includes("iframe")) {
        return (
          <Box
            title={title}
            sx={{
              position: "relative",
              paddingTop: "56.25%", // 16:9 aspect ratio
              height: 0,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={src}
              title={title || "YouTube video player"}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Box>
        );
      } else {
        // Image
        return (
          <Box
            sx={{
              width: "100%",
              maxHeight: { xs: "300px", md: "500px", "2xl": "800px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image
              src={src}
              alt={alt}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
        );
      }
    },

    video: ({ src, ...props }: { src: string; alt?: string }) => {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          <video
            src={src}
            controls
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            {...props}
          />
        </Box>
      );
    },

    a: ({ href, children }: { href: string; children: ReactNode }) => {
      return (
        <CustomLink
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            color: "#90caf9",
            fontWeight: "500",
          }}
        >
          {children}
        </CustomLink>
      );
    },

    // Avoid potential invalid HTML nesting
    p: ({ children }: { children: ReactNode }) => {
      const hasNonTextOrSpanChildren = Children.toArray(children).some(
        (child) =>
          isValidElement(child) &&
          child.type !== "span" &&
          typeof child !== "string"
      );
      // If non-text/non-span children, use a div
      if (hasNonTextOrSpanChildren) {
        return <div style={{ width: "100%" }}>{children}</div>;
      }
      // Otherwise, use a p
      return <p>{children}</p>;
    },

    ol: ({ children }: { children: ReactNode }) => (
      <ol style={{ width: "100%", paddingLeft: 0 }}>{children}</ol>
    ),

    li: ({ children }: { children: ReactNode }) => (
      <li style={{ marginBottom: "12px", marginLeft: "20px" }}>{children}</li>
    ),

    hr: () => <hr style={{ width: "100%", borderColor: "#414850" }} />,

    h1: Heading("h1"),
    h2: Heading("h2"),
    h3: Heading("h3"),
    h4: Heading("h4"),
    h5: Heading("h5"),
    h6: Heading("h6"),

    blockquote: ({ children }: { children: ReactNode }) => (
      <Box
        sx={{
          width: "100%",
          p: 3,
          backgroundColor: "#8199e22b",
          borderRadius: "10px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TipsAndUpdates /> {children}
      </Box>
    ),
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={renderers as Components}
    >
      {sanitizedMarkdown}
    </ReactMarkdown>
  );
};

export default DisplayMarkdown;
