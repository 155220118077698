import { useState } from "react";
import { Alert, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import { useRouter } from "next/navigation";

function Auth(props) {
  const router = useRouter();
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [formAlert, setFormAlert] = useState(null);
  const [signupUser, setSignupUser] = useState(null);
  const [isSocial, setIsSocial] = useState(false);

  // This is what should happen after signin/signup completes
  const handleAuth = async (user, callIsSocial) => {
    try {
      props.setPending(true);
      if (["signup"].includes(props.type)) {
        setFormAlert(null);
        if (!isSocial && !callIsSocial) {
          props.setSignupSuccess(true);
          setSignupUser(user);
        }
        props.setPending(false);
        return;
      } else {
        // Remove anything left in browser by previous user (should already be removed on logout)
        localStorage.removeItem("activeStep");
        localStorage.removeItem("collectedData");
        router.push(props.afterAuthPath);
        props.handleCloseModal();
      }
    } catch (error) {
      // Handle errors
      handleFormAlert({
        type: "error",
        message: error.message,
      });
      props.setPending(false);
    }
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={1}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      {/* Content above email & password form */}
      {["signup", "signin"].includes(props.type) &&
        !props.signupSuccess &&
        !props.shouldVerify && (
          <>
            {/* OAuth providers */}
            {props.providers?.length && (
              <>
                <AuthSocial
                  buttonAction={props.buttonAction}
                  providers={props.providers}
                  showLastUsed={true}
                  onAuth={handleAuth}
                  onError={(message) => {
                    handleFormAlert({
                      type: "error",
                      message: message,
                    });
                  }}
                  setIsSocial={setIsSocial}
                />
              </>
            )}

            {/* "Or with email" text */}
            {!isSmOrSmaller && (
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ flex: 1, height: "1px", bgcolor: "accents.light" }}
                ></Box>
                <Typography
                  sx={{
                    mx: 2,
                    fontSize: ".8rem",
                    textTransform: "uppercase",
                    color: "accents.light",
                    whiteSpace: "nowrap",
                  }}
                >
                  Or with email & password
                </Typography>
                <Box
                  sx={{ flex: 1, height: "1px", bgcolor: "accents.light" }}
                ></Box>
              </Box>
            )}
          </>
        )}

      {/* Email & pw form (This form displays regardless of whether user uses an OAuth provider; we don't know yet what they want) */}
      <AuthForm
        type={props.type}
        setType={props.setType}
        buttonAction={props.buttonAction}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
        signupSuccess={props.signupSuccess}
        setSignupSuccess={props.setSignupSuccess}
        pending={props.pending}
        setPending={props.setPending}
        signupUser={signupUser}
        shouldVerify={props.shouldVerify}
        setShouldVerify={props.setShouldVerify}
        isSocial={isSocial}
        isSmOrSmaller={isSmOrSmaller}
        handleCloseModal={props.handleCloseModal}
      />
    </>
  );
}

export default Auth;
