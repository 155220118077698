"use client";
import { useEffect, useState } from "react";
import BadgeHandler from "./BadgeHandler";
import SnackbarHandler from "./SnackbarHandler";
import { setBadgeUpdater } from "../util/badgeUpdater";
import { setSnackbarUpdater } from "../util/snackbarUpdater";

const Notifications = () => {
  const [badges, setBadges] = useState([]);
  const [snackbar, setSnackbar] = useState({ message: null, severity: "info" });

  useEffect(() => {
    setBadgeUpdater(setBadges);
    setSnackbarUpdater(setSnackbar);
  }, []);

  return (
    <>
      {/* Badge notification popup(s) */}
      {Array.isArray(badges) && badges.length > 0 && (
        <BadgeHandler badges={badges} setBadges={setBadges} />
      )}

      {/* Snackbar notification */}
      {snackbar && snackbar.message && (
        <SnackbarHandler
          content={snackbar}
          setMessage={() => setSnackbar({ message: null, severity: "info" })}
        />
      )}
    </>
  );
};

export default Notifications;
