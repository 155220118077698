import { Box, useTheme } from "@mui/material";

function LongContent(props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...theme.typography.body1,
        "& h1": {
          ...theme.typography.h4,
          fontWeight: 600,
        },
        "& h2": {
          ...theme.typography.h5,
          fontWeight: 600,
        },
        "& h3": {
          ...theme.typography.h6,
          fontWeight: 600,
        },
        "& a": {
          color: theme.palette.primary.main,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        ...props.customSx,
      }}
    >
      {props.children}
    </Box>
  );
}

export default LongContent;
