"use client";
import { useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { useMediaQuery, useTheme } from "@mui/material";
import Navbar from "./Navbar";

const NavbarWrapper = () => {
  const navbarRef = useRef();
  const pathname = usePathname();
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const isAuthPage = pathname.startsWith("/auth");
  const [webcamOpen, setWebcamOpen] = useState(false);

  const shouldHideNavbar = !isSmOrSmaller && isAuthPage;

  return (
    <>
      {!shouldHideNavbar && (
        <Navbar
          ref={navbarRef}
          color="primary"
          webcamOpen={webcamOpen}
          setWebcamOpen={setWebcamOpen}
        />
      )}
    </>
  );
};

export default NavbarWrapper;
