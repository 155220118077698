import { useState } from "react";
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Card,
  CardContent,
  Link,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
  PersonAdd,
  TouchApp,
  Download,
  UnfoldMore,
  UnfoldLess,
} from "@mui/icons-material";

const NewExperimentGenomeCardLarge = ({
  canChooseGenome,
  collectedData,
  description,
  faveCount,
  FeaturesDropdown,
  features,
  getSharingTitle,
  handleCancelEdit,
  handleCurrentAugmentClose,
  handleDownloadClick,
  handleEdit,
  handleSeedChangeClose,
  handleSelect,
  handleStartEdit,
  isEditing,
  isFave,
  isHub,
  isHubModal,
  isPublic,
  onComplete,
  ref,
  row,
  selectedFeatures,
  setDescription,
  setOpenDeleteCheck,
  setSelectedFeatures,
  setShareOpen,
  setTitle,
  title,
  toggleFave,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMore, setShowMore] = useState(false);

  return (
    <Card
      ref={ref ? ref : undefined}
      sx={{
        position: "relative",
        overflow: "visible",
        width: "100%",
        // "&:hover .hoverButton": {
        //   opacity: 1,
        // },
        border: "1px solid",
        backgroundColor: "background.dark",
        borderColor: "accents.superDark",
      }}
    >
      {/* Badge at the top of the card */}
      {row.genome_tier && row.genome_tier !== "starter-user" && (
        <Box
          sx={{
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            px: 1,
            py: 0.5,
            border: "1px solid #222629",
            borderRadius: "20px",
            bgcolor: "background.default",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
              textTransform: "uppercase",
              color: "#737373",
            }}
          >
            {row.genome_tier === "ultimate-user" ? "Ultimate" : "Pro"}
          </Typography>
        </Box>
      )}

      <CardContent
        sx={{
          width: "100%",
          pt: "16px",
          pb: "16px !important",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        {/* Starter top of card */}
        {row.genome_id === collectedData?.embodimentGenomeId && (
          <Box
            sx={{
              p: "2px 7px",
              fontWeight: "bold",
              backgroundColor: "#90cbf90d",
              color: "primary.main",
              textAlign: "center",
            }}
          >
            Recommended starter genome
          </Box>
        )}
        {/* Stats + main buttons */}
        <Box
          sx={{
            width: "100%",
            mt: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {/* Left Column */}
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {/* Top Left */}
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                overflow="hidden"
                width="80%"
              >
                {/* Title */}
                {isEditing ? (
                  <TextField
                    id="title"
                    type="text"
                    label="Title"
                    name="title"
                    placeholder="Title"
                    required
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    value={title !== null ? title : row.genome_title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ width: "100%", margin: "5px 5px 5px 0" }}
                  />
                ) : (
                  <Box
                    sx={{
                      marginBottom: "10px",
                      ...(!showMore
                        ? {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }
                        : {}),
                    }}
                  >
                    <Typography
                      sx={{
                        overflowWrap: "break-word",
                        fontSize: { xs: "1rem", md: "1.2rem" },
                        fontWeight: 600,
                        whiteSpace: "normal",
                      }}
                    >
                      {row.genome_title}
                    </Typography>
                    {row.creator_display_name && (
                      <Typography
                        sx={{
                          color: "accents.light",
                          fontSize: { xs: ".85rem", md: ".99rem" },
                        }}
                      >
                        by{" "}
                        <Link
                          href={`/user/${row.creator_display_name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.creator_display_name}
                        </Link>
                      </Typography>
                    )}
                  </Box>
                )}
                {/* Features */}
                <Box
                  sx={{
                    marginBottom: "10px",
                    width: "100%",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "4px",
                      maxHeight: !showMore && !isEditing ? "35px" : "none",
                      overflow: !showMore ? "hidden" : "visible",
                    }}
                  >
                    {isEditing ? (
                      <FeaturesDropdown
                        features={features}
                        selectedFeatures={selectedFeatures}
                        setSelectedFeatures={setSelectedFeatures}
                      />
                    ) : (
                      <>
                        {row.tags?.length > 0 && (
                          <>
                            {/* <Box
                              sx={{
                                minHeight: "35px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography fontWeight="700">
                                Features:
                              </Typography>
                            </Box> */}
                            {row.tags.map((feature, index) => {
                              if (!feature.trim().length) return null;
                              return (
                                <Chip
                                  key={index}
                                  label={feature}
                                  sx={{
                                    "&.MuiChip-root": {
                                      backgroundColor: "accents.dark",
                                      borderRadius: "5px",
                                      height: "auto",
                                      minHeight: "1rem",
                                      padding: "3px 0",
                                      "& .MuiChip-label": {
                                        color: "white",
                                        fontWeight: "bold",
                                      },
                                    },
                                  }}
                                />
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // gap: 2,
                  }}
                >
                  {/* Neurons */}
                  {row.neuron_count && (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: 500 }}>
                        Neurons:
                      </Typography>
                      <Typography component="span" sx={{ fontSize: ".9rem" }}>
                        {row.neuron_count
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                          "Unavailable"}{" "}
                      </Typography>
                    </Box>
                  )}
                  {/* Synapses */}
                  {row.synapse_count && (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        component="span"
                        sx={{ fontSize: ".9rem", fontWeight: 500 }}
                      >
                        Synapses:
                      </Typography>{" "}
                      <Typography component="span" sx={{ fontSize: ".9rem" }}>
                        {row.synapse_count
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                          "Unavailable"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {/* Description */}
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {isEditing ? (
                <TextField
                  variant="outlined"
                  type="text"
                  label="Description"
                  name="description"
                  placeholder="description"
                  fullWidth={true}
                  multiline
                  maxRows="30"
                  minRows="1"
                  sx={{
                    m: "5px 5px 5px 0",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  value={
                    description !== null ? description : row.genome_description
                  }
                  onChange={(e) => setDescription(e.target.value)}
                />
              ) : (
                <Typography
                  sx={{
                    ...{
                      fontSize: { xs: "0.9rem", md: ".99rem" },
                      fontStyle: row.genome_description ? "normal" : "italic",
                      color: row.genome_description ? "white" : "accents.light",
                    },
                    ...(showMore
                      ? {}
                      : {
                          wordBreak: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }),
                  }}
                >
                  {row.genome_description || "No description"}
                </Typography>
              )}
            </Box>
          </Box>

          {isEditing && (
            <Box
              sx={{
                width: "100%",
                // p: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                // backgroundColor: "background.default",
                // border: "1px solid",
                // borderColor: "accents.dark",
                // position: "relative",
                // "&::before": {
                //   content: '"Edit Panel"',
                //   position: "absolute",
                //   top: "-0.75em",
                //   left: "1em",
                //   color: "accents.dark",
                //   backgroundColor: "background.dark",
                //   padding: "0 0.5em",
                //   fontSize: "0.7rem",
                // },
              }}
            >
              {/* Save / cancel edits icons */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 0.5,
                  borderRadius: "5px",
                }}
              >
                {/* Delete icon */}
                {onComplete && (
                  <Box
                    sx={{
                      gap: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <Tooltip
                      title={
                        isPublic
                          ? "Cannot delete while public"
                          : "Delete genome"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            setOpenDeleteCheck(true);
                          }}
                          disabled={isPublic}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                            color: isPublic ? "accents.main" : "error.main",
                          }}
                        >
                          <DeleteIcon />
                          <Typography
                            component="span"
                            sx={{ fontSize: ".9rem" }}
                          >
                            Delete
                          </Typography>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                )}
                {/* Cancel */}
                <Tooltip title={"Cancel editing"}>
                  <IconButton
                    color="white"
                    size="small"
                    onClick={handleCancelEdit}
                    sx={{ display: "flex", gap: "3px", alignItems: "center" }}
                  >
                    <CancelIcon />
                    <Typography component="span" sx={{ fontSize: ".9rem" }}>
                      Cancel
                    </Typography>
                  </IconButton>
                </Tooltip>
                {/* Save */}
                <Tooltip title="Save edits">
                  <span>
                    <IconButton
                      size="small"
                      color="success"
                      onClick={handleEdit}
                      sx={{ display: "flex", gap: "3px", alignItems: "center" }}
                    >
                      <CheckBoxIcon />
                      <Typography component="span" sx={{ fontSize: ".9rem" }}>
                        Save
                      </Typography>
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          )}

          {/* Bottom */}
          {!isEditing && (
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              {/* Favorite */}
              <Box
                sx={{
                  mr: "auto",
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "3px",
                  padding: "4px 3px",
                  minWidth: "68px",
                }}
              >
                <IconButton
                  size="small"
                  color="gold"
                  onClick={toggleFave}
                  sx={{ p: 0 }}
                >
                  {isFave ? <StarIcon /> : <StarOutlineIcon />}
                </IconButton>
                <Typography
                  sx={{
                    fontFamily: "'Courier New', Courier, monospace",
                    fontSize: ".9rem",
                    fontWeight: 600,
                  }}
                >
                  {faveCount}
                </Typography>
              </Box>

              {/* Edit */}
              {row.owned && !isHub && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleStartEdit}
                  disabled={isEditing}
                >
                  <EditIcon />
                </IconButton>
              )}

              {/* Share */}
              {/* {(row.owned || isPublic) && ( */}
              <Tooltip title={getSharingTitle(row)}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setShareOpen(true)}
                >
                  {/* row.shared_with?.length ? */}
                  <PersonAdd />
                </IconButton>
              </Tooltip>
              {/* )} */}

              {/* Public/Private */}
              {/* {row.owned && (
              <Tooltip title="Change your genome's visibility">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton color="primary" size="small">
                    {isPublic ? (
                      <Public
                        onClick={() => {
                          setConfirmTogglePrivateOpen(true);
                        }}
                      />
                    ) : (
                      <PublicOff
                        onClick={() => {
                          setConfirmTogglePublicOpen(true);
                        }}
                      />
                    )}
                  </IconButton>
                  <Switch
                        onClick={() => {
                          isPublic
                            ? setConfirmTogglePrivateOpen(true)
                            : setConfirmTogglePublicOpen(true);
                        }}
                        checked={!!isPublic}
                      /> 
                </Box>
              </Tooltip>
            )} */}

              {/* Parentage */}
              {/* <Tooltip
                        title={
                          handleCurrentClose
                            ? "Only viewing one layer of ancestry is currently supported"
                            : "View genealogy"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() => setGenealogyOpen(true)}
                            disabled={handleCurrentClose}
                            sx={{ marginRight: row.owned ? "5px" : "20px" }}
                          >
                            <AccountTree />
                          </IconButton>
                        </span>
               </Tooltip> */}

              {!isHub && (
                <IconButton
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleDownloadClick}
                  // className="hoverIconButton"
                >
                  <Download />
                </IconButton>
              )}

              {/* Show more icon */}
              <Tooltip title={`Show ${showMore ? "less" : "more"} details`}>
                <IconButton
                  color="primary"
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  {showMore ? <UnfoldLess /> : <UnfoldMore />}
                </IconButton>
              </Tooltip>

              {/* Download button */}
              {isHub && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleDownloadClick}
                  // className="hoverButton"
                  sx={{
                    fontWeight: "600",
                    ...(isXs && { width: "30px", py: 0.3 }),
                    minWidth: 0,
                  }}
                >
                  {isXs ? <Download /> : "Download"}
                </Button>
              )}

              {/* Choose button */}
              <Box display="flex">
                {!isHub &&
                  !isHubModal &&
                  (onComplete ||
                    handleSeedChangeClose ||
                    handleCurrentAugmentClose) && (
                    <Tooltip
                      title={
                        isEditing
                          ? "Confirm or cancel edits to choose"
                          : !canChooseGenome
                          ? `This genome requires ${
                              row.genome_tier === "pro-user"
                                ? "a pro"
                                : "an ultimate"
                            } subscription`
                          : undefined
                      }
                    >
                      <span>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          sx={{
                            fontWeight: "600",
                            width: { xs: "30px", sm: "auto" },
                            minWidth: 0,
                            py: { xs: "0.3", sm: "auto" },
                          }}
                          onClick={() => handleSelect(row)}
                          disabled={isEditing || !canChooseGenome}
                        >
                          {isXs ? <TouchApp /> : "Choose"}
                        </Button>
                      </span>
                    </Tooltip>
                  )}
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewExperimentGenomeCardLarge;
