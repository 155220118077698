import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../util/auth";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  ArrowCircleUp as ArrowCircleUpIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  getEmbodiments,
  getEmbodimentFilters,
  getEmbodimentSortOptions,
} from "../../api/embodimentManagement";
import getBrowserInfo from "../../util/getBrowserInfo";
// import NewExperimentEmbodimentCard from "./NewExperimentEmbodimentCard";
import NewExperimentEmbodimentCardSmall from "./NewExperimentEmbodimentCardSmall";
import LazyLoader from "../LazyLoader";
import Filters from "./Filters";
import { logger } from "../../util/logger";

export default function NewExperimentEmbodiment({
  onComplete,
  collectedData,
  setCollectedData,
  setError,
}) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [browserInfo, setBrowserInfo] = useState(null);
  // Filters
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const filtersOpen = Boolean(filtersAnchorEl);
  const [filterList, setFilterList] = useState({}); // filters list for dropdown
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState({}); // determine what to show in right dropdown container
  // Search
  const searchInputRef = useRef();
  const [searchInput, setSearchInput] = useState("");
  // Sort
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortList, setSortList] = useState({});
  const [selectedSort, setSelectedSort] = useState("");
  // Lazy loading
  const [makeCall, setMakeCall] = useState(false); // determine when to refetch
  const limit = 10; // items per load
  const [showGoUpButton, setShowGoUpButton] = useState(false); // jump to top
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // Apply user's selected environment filter on initial render
    if (collectedData) {
      const environment = collectedData.environmentTitle;
      if (environment) {
        toggleFilter("embodiments", "environment", environment.toLowerCase());
      }
    }

    // Fetch browser info to check bluetooth support
    async function fetchBrowserInfo() {
      const info = await getBrowserInfo();
      setBrowserInfo(info);
    }

    fetchBrowserInfo();
  }, [collectedData]);

  // Function to fetch & set embodiments
  const fetchEmbodiments = async (page = 1) => {
    try {
      // fetch embodiments
      const accessToken = auth?.user?.accessToken;
      const res = await getEmbodiments({
        token: accessToken,
        page,
        limit,
        sortBy: selectedSort,
        search: searchInput,
        filters: selectedFilters,
        context: "experiment",
      });
      // return results to calling function
      if (!res.data) throw new Error("Did not receive data from server");
      return res.data;
      // handle errors
    } catch (err) {
      console.error(err);
      throw new Error(
        "An error occurred fetching the embodiments. Please try again."
      );
    }
    // setLoading(false);
  };

  // Show jump to top button if scrolled more than this many pixels from top, hide otherwise
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowGoUpButton(true);
      } else {
        setShowGoUpButton(false);
      }
    };
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Get sort & filter contents on load
  useEffect(() => {
    const accessToken = auth?.user?.accessToken;

    // Filters
    const fetchEmbodimentFilters = async () => {
      try {
        const response = await getEmbodimentFilters(accessToken);
        if (!response.data) {
          throw new Error("Received no data from the server.");
        }
        setFilterList(response.data);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    // Sort options
    const fetchSortOptions = async () => {
      try {
        const response = await getEmbodimentSortOptions(accessToken);
        if (!response.data) {
          throw new Error("Received no data from the server.");
        }
        setSortList(response.data);
      } catch (error) {
        console.error("Error fetching sort options:", error);
      }
    };

    fetchEmbodimentFilters();
    fetchSortOptions();
    setLoading(false);
  }, [auth?.user?.accessToken]);

  // FILTER
  // Show filter subcategory contents on click
  const handleSubcategoryClick = (category, subcategoryKey) => {
    logger(
      "category, subcategory:",
      category,
      subcategoryKey,
      filterList[category][subcategoryKey]
    );
    setSelectedSubcategory({
      category,
      subcategoryKey, // The key (name) of the subcategory
      subcategory: filterList[category][subcategoryKey], // The actual subcategory object
    });
  };

  // Open filters dropdown
  const handleFiltersClick = (event) => {
    setFiltersAnchorEl(event.currentTarget);
  };

  // Toggle filter
  const toggleFilter = (category, subcategory, value) => {
    logger("toggleFilter:", category, subcategory, value);
    setSelectedFilters((prevFilters) => {
      // Clone the current state to avoid direct mutation
      const newFilters = { ...prevFilters };

      // If the subcategory already exists
      if (newFilters[category] && newFilters[category][subcategory]) {
        // Convert the subcategory value to an array if it's not one
        if (!Array.isArray(newFilters[category][subcategory])) {
          newFilters[category][subcategory] = [
            newFilters[category][subcategory],
          ];
        }

        // Add or remove the value from the array
        const valueIndex = newFilters[category][subcategory].indexOf(value);
        if (valueIndex >= 0) {
          logger("toggle removing");
          newFilters[category][subcategory].splice(valueIndex, 1);
        } else {
          logger("toggle adding");
          newFilters[category][subcategory].push(value);
        }
      } else {
        // If the subcategory doesn't exist, create it and initialize with the value in an array
        newFilters[category] = newFilters[category] || {};
        newFilters[category][subcategory] = [value];
      }

      // Clean up if the category is now empty
      if (
        newFilters[category] &&
        Object.keys(newFilters[category]).length === 0
      ) {
        delete newFilters[category];
      }

      return newFilters;
    });
  };

  // Remove a filter
  const deleteFilter = (category, subcategory, value) => {
    try {
      toggleFilter(category, subcategory, value);
      setMakeCall((prev) => !prev);
    } catch (err) {
      console.error(err);
    }
  };

  // Close filters dropdown
  const handleFiltersClose = () => {
    setFiltersAnchorEl(null);
  };

  // Filter by date
  const handleDateRangeFilter = (category, subcategoryKey, dateRange) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      // Join the date range as a string, e.g., "2023-01-01 - 2023-01-31"
      const formattedDateRange = dateRange.filter(Boolean).join(" - ");

      if (formattedDateRange) {
        // If at least one date is set, update the filter
        newFilters[category] = newFilters[category] || {};
        newFilters[category][subcategoryKey] = formattedDateRange;
      } else {
        // If both dates are null (or empty), remove the filter
        if (newFilters[category]?.[subcategoryKey]) {
          delete newFilters[category][subcategoryKey];
        }
      }

      return newFilters;
    });
  };

  // SORT
  // Open sort dropdown
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  // Close sort dropdown
  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  // SEARCH
  // Set search value
  const handleSearchSubmit = () => {
    console.log("setting search input with:", searchInputRef.current.value);
    if (!isLazyLoading) setSearchInput(searchInputRef.current.value);
  };

  // GET EMBODIMENTS
  // Trigger Lazy Loader to call getEmbodiments on filter change
  useEffect(() => {
    if (!initialLoad) {
      // Run the effect only when filtersAnchorEl changes and not on initial load
      filtersAnchorEl === null && setMakeCall((prev) => !prev);
    } else {
      // Set initialLoad to false after the first render
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAnchorEl]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "sticky",
          top: "0",
          zIndex: "100",
          padding: "10px 0 ",
          backgroundColor: "background.default", // so it looks okay when scrolling
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "15px",
          }}
        >
          {/* Filter button */}
          <Button
            aria-controls="filter-menu"
            aria-haspopup="true"
            aria-expanded={filtersOpen ? "true" : undefined}
            disabled={Object.keys(filterList).length === 0}
            onClick={handleFiltersClick}
            variant="outlined"
            sx={{
              height: "40px",
              borderColor: "accents.main",
              color: "white",
              borderRadius: "4px 0 0 4px",
              "&:hover": {
                borderColor: "accents.main",
                backgroundColor: "primary.superDark",
              },
            }}
          >
            Filters
          </Button>

          {/* Search field */}
          <OutlinedInput
            inputRef={searchInputRef}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={handleSearchSubmit}
                />
              </InputAdornment>
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchSubmit();
              }
            }}
            sx={{
              height: "40px",
              width: "100%",
              color: "white",
              borderRadius: "0 4px 4px 0",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "accents.main", // Default border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit", // Border color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main", // Border color when focused
              },
            }}
          />

          {/* Sort button */}
          <Button
            aria-controls="sort-menu"
            aria-haspopup="true"
            aria-expanded={sortOpen ? "true" : undefined}
            disabled={sortList?.length === 0}
            onClick={handleSortClick}
            variant="outlined"
            sx={(theme) => ({
              marginLeft: "20px",
              height: "40px",
              border: `1px solid ${theme.palette.accents.main}`,
              color: "white",
              "&:hover": {
                border: `1px solid ${theme.palette.accents.main}`,
                backgroundColor: "primary.superDark",
              },
            })}
          >
            Sort
          </Button>

          {/* Sort dropdown */}
          <Menu
            id="sort-menu"
            anchorEl={sortAnchorEl}
            open={sortOpen}
            onClose={handleSortClose}
            MenuListProps={{
              "aria-labelledby": "sort-button",
            }}
            PaperProps={{
              sx: (theme) => ({
                display: "flex",
                maxHeight: "500px", // Adjust height to prevent jumping
                overflow: "hidden", // Prevents the entire menu from scrolling
                padding: "0",
                border: `1px solid ${theme.palette.accents.main}`,
                "& ul": {
                  padding: "0 !important",
                },
              }),
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.accents.main}`,
                paddingLeft: "10px",
              })}
            >
              <Typography fontWeight="600">Sort</Typography>
              <IconButton onClick={handleSortClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              height="200px"
              width="200px"
              sx={{ overflowY: "hidden", maxHeight: "100%" }}
            >
              <RadioGroup
                value={selectedSort}
                onChange={(e) => setSelectedSort(e.target.value)}
              >
                {sortList?.length > 0 &&
                  sortList.map((sortOption) => (
                    <FormControlLabel
                      key={sortOption}
                      value={sortOption}
                      control={<Radio />}
                      label={
                        sortOption.charAt(0).toUpperCase() + sortOption.slice(1)
                      }
                      sx={(theme) => ({
                        padding: "0 5px",
                        margin: 0,
                        borderBottom: `1px solid ${theme.palette.accents.main}`,
                        "&:hover": {
                          backgroundColor: "primary.superDark",
                          color: "white",
                        },
                      })}
                    />
                  ))}
              </RadioGroup>
            </Box>
          </Menu>

          {/* Filter dropdown */}
          <Filters
            filtersAnchorEl={filtersAnchorEl}
            filtersOpen={filtersOpen}
            handleFiltersClose={handleFiltersClose}
            filterList={filterList}
            handleSubcategoryClick={handleSubcategoryClick}
            handleDateRangeFilter={handleDateRangeFilter}
            selectedFilters={selectedFilters}
            selectedSubcategory={selectedSubcategory}
            toggleFilter={toggleFilter}
          />
        </Box>

        {/* Selected filters */}
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            marginTop: "15px",
            flexWrap: "wrap",
          }}
        >
          {Object.entries(selectedFilters).map(([category, subcategories]) =>
            Object.entries(subcategories).map(([subcategory, value]) => {
              // Map values if array
              if (Array.isArray(value)) {
                return value.map((val) => (
                  <Chip
                    key={`${category}-${subcategory}-${val}`}
                    label={val}
                    sx={{ backgroundColor: "tertiary.main", cursor: "pointer" }}
                    onDelete={() => deleteFilter(category, subcategory, val)}
                  />
                ));
              } else {
                // Else handle as a single string
                return (
                  <Chip
                    key={`${category}-${subcategory}-${value}`}
                    label={value}
                    sx={{ backgroundColor: "tertiary.main", cursor: "pointer" }}
                    onDelete={() => deleteFilter(category, subcategory, value)}
                  />
                );
              }
            })
          )}
        </Box>
      </Box>

      {/* Embodiments */}
      <Box
        sx={{
          pt: 2,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box style={{ flexGrow: 1, p: 2, width: "100%" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
            {!loading && (
              <LazyLoader
                fetchFunction={fetchEmbodiments}
                dependencies={[makeCall, selectedSort, searchInput]}
                limit={limit}
                isEmbodiments={true}
                setError={setError}
                loading={isLazyLoading}
                setLoading={setIsLazyLoading}
              >
                {({ items, lastItemRef }) =>
                  items.map((item, index) => {
                    return (
                      <NewExperimentEmbodimentCardSmall
                        key={item.embodiment_id + index}
                        ref={index === items.length - 1 ? lastItemRef : null}
                        row={item}
                        index={index}
                        onComplete={onComplete}
                        collectedData={collectedData}
                        setCollectedData={setCollectedData}
                        browserInfo={browserInfo}
                      />
                    );
                    // if (isSmOrSmaller) {
                    // } else {
                    //   return (
                    //     <NewExperimentEmbodimentCard
                    //       key={item.embodiment_id + index}
                    //       ref={index === items.length - 1 ? lastItemRef : null}
                    //       row={item}
                    //       index={index}
                    //       onComplete={onComplete}
                    //       collectedData={collectedData}
                    //       setCollectedData={setCollectedData}
                    //       browserInfo={browserInfo}
                    //     />
                    //   );
                    // }
                  })
                }
              </LazyLoader>
            )}
          </Box>
        </Box>
      </Box>
      {showGoUpButton && (
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            bottom: "0",
            zIndex: "100",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "background.default",
          }}
          onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicked
        >
          <IconButton>
            <ArrowCircleUpIcon sx={{ color: "accents.main" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
}
